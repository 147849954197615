import React, { useState, useEffect } from 'react'
import API from '../../client/API-client'
import { useLocation } from 'react-router-dom'
import EpisodeOverview from './episode-overview'
import showRoutes from '../../client/show-client'
import Checkbox from '../../components/Icons/checkbox'
import WatchedAllCheckbox from '../../components/Icons/watchedAllCheckbox'
import { Card, CardActions, CardContent, CardMedia, Button, CircularProgress, Link } from '@mui/material'
import { StyledCircularProgressDiv, StyledEpisodesContainer, StyledEpisodesGrid, StyledSeasonsButtonsDiv } from '../../utils/styled'

function Episodes({ show, ShowId, SeasonId, user, ShowTitle, totalSeasons, hasSpecials, showPosterPath }) {
  const [watched, setWatched] = useState()
  const [loading, setLoading] = useState(false)
  const [seasonNumber, setSeasonNumber] = useState(show?.season_number)
  const [episodeRequest, setEpisodeRequest] = useState([])
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      showRoutes.getEpisodes(ShowId, show?.season_number)
        .then(res => {
          setEpisodeRequest(res.data.episodes)
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
        .finally(() => setLoading(false))
    }
    if (!show) return
    fetchData()
  }, [show, ShowId, refresh])

  useEffect(() => {
    if (user?.username) {
      API.getAllEpisodes(user.username).then((res) => {
        setWatched(res.data)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, user])

  useEffect(() => {
    setSeasonNumber(show?.season_number)
  }, [show])

  const hasSpecialsAndFirstSeason = hasSpecials && seasonNumber === 1

  const search = useLocation().search
  const season_number = new URLSearchParams(search).get('season_number')

  return (
    <>
      {loading ? (
        <StyledCircularProgressDiv>
          <CircularProgress size={70} />
        </StyledCircularProgressDiv>
      ) : (
        !episodeRequest.length ? (
          <h1 style={{ marginTop: '10rem' }}>Looks like this show doesn't have specials, click <Link href={`/shows/${ShowId}/season?season_number=1&total=${+totalSeasons}&has_specials=${hasSpecials}`} >here</Link> to go to next season!</h1>
        ) : (
          <StyledEpisodesContainer>
            <div style={{ margin: '1rem' }}>{episodeRequest?.length} Episodes</div>
            <StyledSeasonsButtonsDiv>
              {(hasSpecials === 'true' ? seasonNumber > 0 : seasonNumber > 1) && (
                <Button
                  color='primary'
                  variant='outlined'
                  onClick={() =>
                    window.location.assign(
                      `/shows/${ShowId}/season?season_number=${hasSpecialsAndFirstSeason ? 'specials' : show?.season_number - 1
                      }&total=${+totalSeasons}&has_specials=${hasSpecials}`
                    )
                  }
                >
                  Previous
                </Button>
              )}
              {+show?.season_number < +totalSeasons && (
                <Button
                  color='primary'
                  variant='outlined'
                  onClick={() =>
                    window.location.assign(
                      `/shows/${ShowId}/season?season_number=${show?.season_number + 1
                      }&total=${+totalSeasons}&has_specials=${hasSpecials}`
                    )
                  }
                >
                  Next Season
                </Button>
              )}
            </StyledSeasonsButtonsDiv>
            {season_number !== 'specials' && <WatchedAllCheckbox
              user={user}
              show={show}
              showId={ShowId}
              seasonId={SeasonId}
              showTitle={ShowTitle}
              watched={watched}
              episodeRequest={episodeRequest}
              labelChecked={'Watched All'}
              labelNotChecked={'Add All To Watched'}
              showPosterPath={showPosterPath}
              seasonPosterPath={show.poster_path}
              setRefresh={setRefresh}
              watchedAllLength={episodeRequest?.length}
            />
            }
            <StyledEpisodesGrid>
              {episodeRequest?.map((episode, id) => (
                episode.still_path &&
                <Card sx={{ maxWidth: 486, minHeight: 492 }} key={id}>
                  <CardContent>
                    <CardMedia
                      component="img"
                      height="282"
                      image={`https://image.tmdb.org/t/p/w780${episode.still_path}`}
                    />
                    <EpisodeOverview episode={episode} />
                  </CardContent>
                  <CardActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {season_number !== 'specials' && <Checkbox
                      user={user}
                      show={show}
                      showId={ShowId}
                      showTitle={ShowTitle}
                      episodeTitle={episode.name}
                      episodeId={episode.id}
                      watched={watched}
                      seasonId={SeasonId}
                      labelChecked={'Watched'}
                      episode={episode.episode_number}
                      labelNotChecked={'Add To Watched'}
                      runtime={episode.runtime}
                      showPosterPath={showPosterPath}
                      seasonPosterPath={show.poster_path}
                      refresh={refresh}
                      setRefresh={setRefresh}
                    />}
                  </CardActions>
                </Card>
              ))}
            </StyledEpisodesGrid>
            <StyledSeasonsButtonsDiv>
              {(hasSpecials === 'true' ? seasonNumber > 0 : seasonNumber > 1) && (
                <Button
                  color='primary'
                  variant='outlined'
                  onClick={() =>
                    window.location.assign(
                      `/shows/${ShowId}/season?season_number=${hasSpecialsAndFirstSeason ? 'specials' : show?.season_number - 1
                      }&total=${+totalSeasons}&has_specials=${hasSpecials}`
                    )
                  }
                >
                  Previous Season
                </Button>
              )}
              {+show?.season_number < +totalSeasons && (
                <Button
                  color='primary'
                  variant='outlined'
                  onClick={() =>
                    window.location.assign(
                      `/shows/${ShowId}/season?season_number=${show?.season_number + 1
                      }&total=${+totalSeasons}&has_specials=${hasSpecials}`
                    )
                  }
                >
                  Next Season
                </Button>
              )}
            </StyledSeasonsButtonsDiv>
          </StyledEpisodesContainer >
        )
      )
      }
    </>
  )
}

export default Episodes
