import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import Tooltip from '@mui/material/Tooltip'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import API from '../../client/API-client'
import './icons.css'

function HistoryIcon({ user, movie, setRefresh, refetch }) {
  const [active, setActive] = useState(false)
  const [watchedMovie, setWatchedMovie] = useState([])
  const [watchedShow, setWatchedShow] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  // console.log(movie)

  const isMovie = window.location.href.includes('movies')
  const isShow = window.location.href.includes('shows')
  const isProfile = window.location.href.includes('profile')

  useEffect(() => {
    if (user?.username) {
      setWatchedMovie(user.movieHistory)
      setWatchedShow(user.showHistory)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user?.username) {
      if (watchedMovie?.some((y) => +y.id === +movie.id)) {
        setActive(true)
      } else if (watchedShow?.some((y) => +y.id === +movie.id)) {
        setActive(true)
      } else {
        setActive(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedMovie, watchedShow])

  const handleChange = () => {
    if (user?.username) {
      if (active) {
        if (movie.media_type === 'tv') {
          API.removeShowFromWatched(user?.username, movie.id)
            .then((res) => {
              if (res.status === 200) {
                setActive(false)
                enqueueSnackbar('Successfully removed from your watched history!', {
                  variant: 'success',
                })
              } else {
                enqueueSnackbar('Something went wrong, please try again later', {
                  variant: 'error',
                })
              }
            })
            .catch((err) => {
              console.log(err)
            })
          return
        } else if (movie.media_type === 'movie') {
          API.removeMovieFromWatched(user?.username, movie.id)
            .then((res) => {
              if (res.status === 200) {
                setActive(false)
                enqueueSnackbar('Successfully removed from your watched history!', {
                  variant: 'success',
                })
              } else {
                enqueueSnackbar('Something went wrong, please try again later', {
                  variant: 'error',
                })
              }
            })
            .catch((err) => {
              console.log(err)
            })
          return
        }

        if (isMovie) {
          API.removeMovieFromWatched(user?.username, movie.id)
            .then((res) => {
              if (res.status === 200) {
                setActive(false)
                enqueueSnackbar('Successfully removed from your watched history!', {
                  variant: 'success',
                })
              } else {
                enqueueSnackbar('Something went wrong, please try again later', {
                  variant: 'error',
                })
              }
            })
            .catch((err) => {
              console.log(err)
            })
          return
        } else if (isShow) {
          API.removeShowFromWatched(user?.username, movie.id)
            .then((res) => {
              if (res.status === 200) {
                setActive(false)
                enqueueSnackbar('Successfully removed from your watched history!', {
                  variant: 'success',
                })
              } else {
                enqueueSnackbar('Something went wrong, please try again later', {
                  variant: 'error',
                })
              }
            })
            .catch((err) => {
              console.log(err)
            })
          return
        }
      } else {
        console.log(movie)
        const genres = movie?.genre_ids ? movie?.genre_ids : movie?.genres
        if (movie.media_type === 'tv') {
          API.addShowToWatched(user?.username, movie.id, movie.poster_path, movie.original_name, genres, movie?.number_of_episodes ?? movie?.number_of_episodes, movie.number_of_seasons ?? movie.number_of_seasons)
            .then((res) => {
              if (res.status === 200) {
                setActive(true)
                enqueueSnackbar('Successfully added to your watched history!', {
                  variant: 'success',
                })
              } else {
                enqueueSnackbar('Something went wrong, please try again later', {
                  variant: 'error',
                })
              }
            })
            .catch((err) => {
              console.log(err)
              enqueueSnackbar('Something went wrong, please try again later', {
                variant: 'error',
              })
            })
          return
        } else if (movie.media_type === 'movie') {
          API.addMovieToWatched(user?.username, movie.id, movie.poster_path, movie.original_title, genres)
            .then((res) => {
              if (res.status === 200) {
                setActive(true)
                enqueueSnackbar('Successfully added to your watched history!', {
                  variant: 'success',
                })
              } else {
                enqueueSnackbar('Something went wrong, please try again later', {
                  variant: 'error',
                })
              }
            })
            .catch((err) => {
              console.log(err)
              enqueueSnackbar('Something went wrong, please try again later', {
                variant: 'error',
              })
            })
          return
        }

        // this makes a quick fix for adding movies to watched/history list from profile
        const posterPath = movie.poster_path.includes('/') ? movie.poster_path : `/${movie.poster_path}`
        if (isMovie || movie.isMovie) {
          API.addMovieToWatched(user?.username, movie.id, posterPath, movie.original_title || movie.title, genres)
            .then((res) => {
              if (res.status === 200) {
                setActive(true)
                setRefresh && setRefresh(true)
                refetch()
                enqueueSnackbar('Successfully added to your watched history!', {
                  variant: 'success',
                })
              } else {
                enqueueSnackbar('Something went wrong, please try again later', {
                  variant: 'error',
                })
              }
            })
            .catch((err) => {
              console.log(err)
              setRefresh && setRefresh(false)
              refetch()
              enqueueSnackbar('Something went wrong, please try again later', {
                variant: 'error',
              })
            })
          return
        } else if (isShow || !movie.isMovie) {
          // this makes a quick fix for adding movies to watched/history list from profile
          const posterPath = movie.poster_path.includes('/') ? movie.poster_path : `/${movie.poster_path}`
          API.addShowToWatched(user?.username, movie.id, posterPath, movie.original_name, genres, movie?.number_of_episodes ?? movie?.number_of_episodes, movie.number_of_seasons ?? movie.number_of_seasons)
            .then((res) => {
              if (res.status === 200) {
                setActive(true)
                enqueueSnackbar('Successfully added to your watched history!', {
                  variant: 'success',
                })
              } else {
                enqueueSnackbar('Something went wrong, please try again later', {
                  variant: 'error',
                })
              }
            })
            .catch((err) => {
              console.log(err)
              enqueueSnackbar('Something went wrong, please try again later', {
                variant: 'error',
              })
            })
          return
        }
      }
    } else {
      enqueueSnackbar('You are not logged in!', {
        variant: 'warning',
      })
    }
  }

  return (
    <>
      <div className='history-icon'>
        <Tooltip title={active ? 'Remove From Watched' : 'Add To Watched'}>
          <div>
            {active ? (
              !isProfile && <AccessTimeFilledIcon style={{ fill: 'green' }} onClick={handleChange} />
            ) : (
              <AccessTimeIcon onClick={handleChange} />
            )}
          </div>
        </Tooltip>
      </div>
    </>
  )
}

export default HistoryIcon
