import React, { useEffect, useState } from 'react'
import { Typography, Card, CardContent, Divider, Button, Tooltip } from '@mui/material'
import styled from 'styled-components'

const StyledMainDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: opacity ease-in-out 0.8s;
`
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
`

const UserComponents = ({
  finishedShows,
  sumOfAllWatchedEpisodes,
  favoriteMovies,
  favoriteShows,
  movieWatchList,
  showWatchList,
  watchedShows,
  watchedMovies,
}) => {
  const [opacity, setOpacity] = useState(0)

  const watchList = () => {
    if (!movieWatchList && !showWatchList) return 0
    if (!movieWatchList && showWatchList) return showWatchList?.length
    if (movieWatchList && !showWatchList) return movieWatchList?.length

    return showWatchList.length + movieWatchList.length
  }

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1)
    }, 700)
  }, [])
  return (
    <StyledMainDiv style={{ opacity: opacity }}>
      <Tooltip arrow title='Coming Soon!'>
        <div>
          <Button disabled fullWidth sx={{ color: 'coral', p: 1, mb: 2.5 }}>
            Add Friend
          </Button>
        </div>
      </Tooltip>
      <Card sx={{ minWidth: 385, p: 0.2 }}>
        <CardContent>
          <StyledDiv>
            <Typography variant='h6'>Finished Shows:</Typography>
            <Typography sx={{ color: 'coral' }} variant='h6'>
              {finishedShows.toLocaleString('en-US') ?? 0}
            </Typography>
          </StyledDiv>
          <Divider />
          <StyledDiv>
            <Typography variant='h6'>Total Watched Episodes:</Typography>
            <Typography sx={{ color: 'coral' }} variant='h6'>
              {sumOfAllWatchedEpisodes.toLocaleString('en-US') ?? 0}
            </Typography>
          </StyledDiv>
          <Divider />
          <StyledDiv>
            <Typography variant='h6'>Favorites:</Typography>
            <Typography sx={{ color: 'coral' }} variant='h6'>
              {favoriteMovies || favoriteShows
                ? (favoriteMovies?.length + favoriteShows?.length).toLocaleString('en-US')
                : 0}
            </Typography>
          </StyledDiv>
          <Divider />
          <StyledDiv>
            <Typography variant='h6'>Watchlist:</Typography>
            <Typography sx={{ color: 'coral' }} variant='h6'>
              {watchList()}
            </Typography>
          </StyledDiv>
          <Divider />
          <StyledDiv>
            <Typography variant='h6'>Watched Shows:</Typography>
            <Typography sx={{ color: 'coral' }} variant='h6'>
              {watchedShows?.length.toLocaleString('en-US') ?? 0}
            </Typography>
          </StyledDiv>
          <Divider />
          <StyledDiv>
            <Typography variant='h6'>Watched Movies:</Typography>
            <Typography sx={{ color: 'coral' }} variant='h6'>
              {watchedMovies?.length.toLocaleString('en-US') ?? 0}
            </Typography>
          </StyledDiv>
          <Divider />
        </CardContent>
      </Card>
    </StyledMainDiv>
  )
}

export default UserComponents
