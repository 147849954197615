import React, { useEffect, useState } from 'react'
import { base_url } from '../../utils/helper'
import { CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import { StyledRowCard, StyledRowCardPersonMedia, StyledRowTypography } from '../../utils/styled'

function People({ fetchUrl, title }) {
  const [actors, setActors] = useState([])
  const [loading, setLoading] = useState(false)
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setOpacity('1')
    }, 800)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      fetchUrl
        .then(res => {
          if (!res) return
          setActors(res.data)
        })
        .finally(() => {
          setLoading(false)
        })
    }
    fetchData()
  }, [fetchUrl])

  const handleClick = (actor) => {
    window.location.assign(`/actors/${actor.id}/${actor.name}`)
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '10rem',
          }}
        >
          <CircularProgress size={70} />
        </div>

      ) : (
        <Grid container>
          <Grid item xs={12}>
            <StyledRowTypography variant="h4">{title}</StyledRowTypography>
            <Grid container justifyContent="center" spacing={5}>
              {actors?.map((actor, key) => (
                actor &&
                <Grid item key={key}>
                  <StyledRowCard
                    sx={{
                      maxWidth: 312,
                      minHeight: 500,
                      transition: 'box-shadow .7s ease-in-out',
                      '&: hover': {
                        boxShadow: '0 3px 8px 0 rgba(255, 127, 80, 0.8), 0 1px 30px 0 rgba(255, 127, 80, 0.9)'
                      }
                    }}
                  >
                    <StyledRowCardPersonMedia
                      style={{ opacity: opacity }}
                      onClick={() => handleClick(actor)}
                      sx={[{ height: 468, width: 312, backgroundSize: 'contain' }]}
                      image={
                        (actor.poster_path || actor.backdrop_path || actor.profile_path) &&
                        `${base_url}${actor.poster_path || actor.backdrop_path || actor.profile_path}`
                      }
                    />
                    <CardContent>
                      <Typography variant='h6' style={{ textAlign: 'center' }} onClick={() => handleClick(actor)}>{actor.name}</Typography>
                    </CardContent>
                  </StyledRowCard>
                </Grid>
              )
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default People