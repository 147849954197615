import React from 'react'
import styled from 'styled-components'
import { Divider, Link } from '@mui/material'

const StyledFooter = styled.footer`
  padding: 20px;
  text-align: center;
  width: 100%;
  gap: 1rem;
`
const StyledMainFooterDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  padding: 0rem 1rem;
  &: hover {
    color: #fff
  }
`
const StyledLinksDiv = styled.div`
  margin: 1rem;
`

const Footer = () => {
  const footerLinks = [
    {
      title: 'About',
      href: '/about'
    },
    {
      title: 'support@movieception.com',
      href: 'mailto:support@movieception.com'
    },
    {
      title: 'Built with TMDB API',
      href: 'https://developer.themoviedb.org/reference/intro/getting-started'
    }
    // {
    //   title: 'Privacy Policy',
    //   href: '/legal/privacy-policy'
    // },
    // {
    //   title: 'Terms of Service',
    //   href: '/legal/tos'
    // },
  ]
  const sxStyling = {
    textDecoration: 'none',
    color: '#fff',
    borderRight: '1px solid #fff'
  }
  const sxLastStyling = {
    textDecoration: 'none',
    color: '#fff'
  }

  return (
    <StyledFooter>
      <Divider sx={{ marginBottom: '1rem' }} />
      <StyledMainFooterDiv>
        <StyledLinksDiv>
          {footerLinks.map((link, idx) => (
            <StyledLink key={idx} sx={idx === footerLinks.length - 1 ? sxLastStyling : sxStyling} href={link.href}>
              {link.title}
            </StyledLink>
          ))}
        </StyledLinksDiv>
        {/* copyright the name movieception n enable this; */}
        {/* <div>
          Copyright © 2023 Movieception All rights reserved
        </div> */}
      </StyledMainFooterDiv>
    </StyledFooter>
  )
}

export default Footer