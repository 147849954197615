import API from '../client/API-client'

export const auth = user => {
  const info = {
    nickname: user?.nickname,
    sub: user?.sub.split('|')[1],
    email: user?.email,
    emailVerified: user?.email_verified,
    updatedAt: user?.updated_at
  }

  API.sendUserInfo(info)
    .then(res => { })
    .catch(err => console.log('line 17: ', err))
}