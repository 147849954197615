import React from 'react'
import { Button, Modal, Typography } from '@mui/material'
import { StyledContainerForSearchModal } from '../../utils/styled'

const RandomModal = ({ randomModal, setRandomModal }) => {
  return (
    <Modal open={randomModal} onClose={() => setRandomModal(false)}>
      <StyledContainerForSearchModal maxWidth='md'>
        <Typography variant='h6' sx={{ mb: 2 }}>
          Get Random 5 Movies or TV Shows
          <br />
          Out of 100 Trending
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
          <div>
            <Button
              variant='outlined'
              onClick={() => window.location.assign('/random?movie=true')}
            >
              Movie
            </Button>
          </div>
          <div>
            <Button
              variant='outlined'
              onClick={() => window.location.assign('/random?movie=false')}
            >
              TV Show
            </Button>
          </div>
        </div>
      </StyledContainerForSearchModal>
    </Modal>
  )
}

export default RandomModal