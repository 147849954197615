import React, { useEffect, useState } from 'react'
import API from '../../client/API-client'
import { useSnackbar } from 'notistack'
import { Checkbox, Tooltip } from '@mui/material'

function CheckboxIcon(props) {
  const [checked, setChecked] = useState()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (props.user?.username) {
      const watchedShow = props.watched?.watchedEpisodes?.find((x) => x.show_title === props.showTitle)
      const season = watchedShow?.seasons
      const episodesArr = season?.find((e) => e.season_id === +props.seasonId)
      if (season && episodesArr) {
        if (episodesArr?.episodes?.some((e) => e.id === +props.episodeId)) {
          setChecked(true)
        } else {
          setChecked(false)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.watched, props.refresh])

  const handleWatched = () => {
    if (props.user?.username) {
      if (checked) {
        API.removeEpisodeFromWatched(
          props.user.username,
          props.showId,
          props.showTitle,
          props.seasonId,
          props.episode,
          props.episodeId
        )
          .then((res) => {
            if (res.status === 200) {
              setChecked(false)
              enqueueSnackbar('Successfully removed from your watched episodes!', {
                variant: 'success',
              })
            } else {
              enqueueSnackbar('Something went wrong, please try again later', {
                variant: 'error',
              })
            }
          })
          .catch((err) => {
            console.log(err)
            enqueueSnackbar('Something went wrong, please try again later', {
              variant: 'error',
            })
          })
      } else {
        API.addEpisodetoWatched(
          props.user.username,
          props.showId,
          props.showTitle,
          props.seasonId,
          props.episode,
          props.episodeId,
          props.episodeTitle,
          props.runtime,
          props.showPosterPath,
          props.seasonPosterPath
        )
          .then((res) => {
            if (res.status === 200) {
              setChecked(true)
              enqueueSnackbar('Successfully added to your watched episodes!', {
                variant: 'success',
              })
            } else {
              enqueueSnackbar('Something went wrong, please try again later', {
                variant: 'error',
              })
            }
          })
          .catch((err) => {
            enqueueSnackbar('Something went wrong, please try again later', {
              variant: 'error',
            })
          })
      }
    } else {
      enqueueSnackbar('You are not logged in!', {
        variant: 'warning',
      })
    }
  }

  return (
    <>
      {props.user?.username && (
        <div>
          <Tooltip title={checked ? props.labelChecked : props.labelNotChecked}>
            <Checkbox checked={checked ?? false} onClick={() => handleWatched()} />
          </Tooltip>
          <div>{checked ? props.labelChecked : props.labelNotChecked}</div>
        </div>
      )}
    </>
  )
}

export default CheckboxIcon
