const href = (movie, isNetflix, isMovie, isShow) => {
  let href = ''

  if (movie?.media_type === 'tv' || isNetflix) {
    href = `/shows/${movie.id}/${movie?.title || movie?.original_title || movie?.name || movie?.original_name}`
  } else if (movie.media_type === 'movie') {
    href = `/movies/${movie.id}/${movie?.title || movie?.original_title || movie?.name || movie?.original_name}`
  } else if (movie.media_type === 'person') {
    href = `/actors/${movie.id}/${movie?.title || movie?.original_title || movie?.name || movie?.original_name}`
  }

  if (isMovie) {
    href = `/movies/${movie.id}/${movie?.title || movie?.original_title || movie?.name || movie?.original_name}`
  } else if (isShow) {
    href = `/shows/${movie.id}/${movie?.title || movie?.original_title || movie?.name || movie?.original_name}`
  }

  return href
}

export const setHref = (movie, isNetflix, isMovie, isShow, marvel) => {

  if (!marvel) {
    movie.data.map(m => (
      m.href = href(m, isNetflix, isMovie, isShow)
    ))
  } else {
    movie.data.results.map(m => (
      m.href = href(m, isNetflix, isMovie, isShow)
    ))
  }

  return marvel ? movie.data.results : movie.data
  // TO BE IMPLEMENTED FOR COLLECTION ON SEARCH IF I DECIDE TO DO IT
  // if (input === 'collection') {
  //   href = `/movies/collections/${movie.id}`
  // } else if (input === 'show') {
  //   href = `/shows/${movie.id}/${movie?.title || movie?.original_title || movie?.name || movie?.original_name}`
  // } else if (input === 'actor') {
  //   href = `/actors/${movie.id}/${movie?.title || movie?.original_title || movie?.name || movie?.original_name}`
  // } else if (input === 'movie') {
  //   href = `/movies/${movie.id}/${movie?.title || movie?.original_title || movie?.name || movie?.original_name}`
  // }
}