import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import movieClient from '../../client/movie-client'
import showClient from '../../client/show-client'
import { CircularProgress, Grid, Skeleton, Typography, Button, Link, useMediaQuery } from '@mui/material'
import { StyledRowCardActions, StyledRowCard, StyledRowCardMedia, StyledCircularProgressDiv } from '../../utils/styled'
import { base_url } from '../../utils/helper'
import styled from 'styled-components'

const StyledTypography = styled(Typography)`
  text-align: center;
  max-width: 270px;
  text-wrap: wrap;
`

const RandomResults = ({ user }) => {
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(1)
  const [movie, setMovie] = useState()
  const [count, setCount] = useState(5)
  const [opacity, setOpacity] = useState(0)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [countLoading, setCountLoading] = useState(false)

  const search = useLocation().search
  const isMovie = new URLSearchParams(search).get('movie')

  const mediaMW600 = useMediaQuery('(min-width:620px)')

  useEffect(() => {
    setTimeout(() => {
      setOpacity('1')
    }, 1000)
  }, [countLoading])

  useEffect(() => {
    setLoading(true)
    if (isMovie === 'false') {
      Promise.all([
        showClient.getTrending(page),
        showClient.getTrending(page + 1),
        showClient.getTrending(page + 2),
        showClient.getTrending(page + 3),
        showClient.getTrending(page + 4)
      ])
        .then(res => {
          if (res.length < 1) return setError(true)
          // a way of adding all returned items into one array;
          const movie = [].concat.apply([], res.map(r => r.data.map(e => e)))
          setMovie(movie)
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
        .finally(() => setLoading(false))
    } else {
      Promise.all([
        movieClient.getTrending(page),
        movieClient.getTrending(page + 1),
        movieClient.getTrending(page + 2),
        movieClient.getTrending(page + 3),
        movieClient.getTrending(page + 4)
      ])
        .then(res => {
          if (res.length < 1) return setError(true)
          // a way of adding all returned items into one array;
          const movie = [].concat.apply([], res.map(r => r.data.map(e => e)))
          setMovie(movie)
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
        .finally(() => setLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMovie])

  const handleClick = (movie) => {
    if (movie.media_type === 'tv') {
      window.location.assign(`/shows/${movie.id}/${movie?.title || movie?.original_title || movie?.name || movie?.original_name}`)
    } else if (movie.media_type === 'movie') {
      window.location.assign(`/movies/${movie.id}/${movie?.title || movie?.original_title || movie?.name || movie?.original_name}`)
    } else if (movie.media_type === 'person') {
      window.location.assign(`/actors/${movie.id}/${movie?.title || movie?.original_title || movie?.name || movie?.original_name}`)
    }
  }

  const sliced = movie?.sort(() => 0.5 - Math.random()).slice(0, 5)

  const handleCount = () => {
    setCountLoading(true)
    setOpacity('0')
    setTimeout(() => {
      setCount(count)
      setCountLoading(false)
    }, 800)
  }

  return (
    <>
      {error ? (
        <Typography
          variant='h4'
          sx={{
            textAlign: 'center',
            opacity: opacity,
            transition: 'opacity 1s ease-in-out',
            m: 1
          }}
        >
          Something went wrong, you can retry by clicking <Link color='secondary' underline='none' href='#' sx={{ cursor: 'pointer', '&: hover': { color: 'coral', textDecoration: 'none' } }} onClick={() => window.location.reload()}>here</Link>, or you can click <Link color='secondary' underline='none' href='/home' sx={{ cursor: 'pointer', '&: hover': { color: 'coral', textDecoration: 'none' } }}>here</Link> to go back home!
        </Typography>
      ) : (
        loading || countLoading ? (
          <StyledCircularProgressDiv>
            <CircularProgress size={70} />
          </StyledCircularProgressDiv>
        ) : (
          <Grid container>
            <Grid item xs={12} sx={{ opacity: opacity, transition: 'opacity ease-in-out 1s' }}>
              {!mediaMW600 &&
                <div style={{ display: 'flex', justifyContent: 'center', margin: '2rem' }}>
                  <Button onClick={() => handleCount()} variant='outlined'>5 More</Button>
                </div>
              }
              <Grid container justifyContent="center" spacing={5}>
                {sliced?.map((movie, key) =>
                  (movie?.poster_path || movie?.backdrop_path || movie?.profile_path) && (
                    <Grid item key={key}>
                      <StyledRowCard
                        sx={{
                          maxWidth: 312,
                          minHeight: 550,
                          transition: 'box-shadow .7s ease-in-out',
                          '&: hover': {
                            boxShadow: '0 3px 8px 0 rgba(255, 127, 80, 0.8), 0 1px 30px 0 rgba(255, 127, 80, 0.9)'
                          }
                        }}
                      >
                        {!(movie?.poster_path || movie?.backdrop_path || movie?.profile_path) ? (
                          <Skeleton variant="rectangular" width={312} height={468} />
                        ) : (
                          <StyledRowCardMedia
                            onClick={() => handleClick(movie)}
                            sx={[{ height: 468, width: 312, backgroundSize: 'contain', mb: 1 }]}
                            image={
                              (movie?.poster_path || movie?.backdrop_path || movie?.profile_path) &&
                              `${base_url}${movie?.poster_path || movie?.backdrop_path || movie?.profile_path}`
                            }
                          />
                        )}
                        <StyledRowCardActions>
                          <StyledTypography
                            component={'span'}
                            variant='h6'
                          >
                            {movie?.title || movie?.original_title || movie?.name || movie?.original_name}
                          </StyledTypography>
                        </StyledRowCardActions>
                      </StyledRowCard>
                    </Grid>
                  )
                )}
              </Grid>
              {!countLoading &&
                <div style={{ display: 'flex', justifyContent: 'center', margin: '2rem' }}>
                  <Button onClick={() => handleCount()} variant='outlined'>5 More</Button>
                </div>
              }
            </Grid>
          </Grid>
        )
      )}
    </>
  )
}

export default RandomResults