import React from 'react'
import styled from 'styled-components'

const StyledBannerDiv = styled.div`
  margin: -5rem -10rem 0rem -10rem;
  @media (max-width: 882px) {
    margin: -3rem -3rem 0rem -3rem;
  }
`
const StyledFadeBottom = styled.div`
  position: relative;
  bottom: 6rem;
  height: 6rem;
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.61), #121212);
`

const Banner = ({ user }) => {
  // const [link, setLink] = useState("")
  // useEffect(() => {
  //   if (!user) return
  //   const favorites = [].concat(user.movieFavorites, user.showFavorites)
  //   const randomIndex = Math.floor(Math.random() * favorites.length)
  //   setLink(favorites[randomIndex].poster_path)
  // }, [user])

  return (
    <StyledBannerDiv>
      <div
        style={{
          backgroundSize: 'cover',
          backgroundImage: `url('/banner.jpg')`,
          // backgroundImage: `url(https://image.tmdb.org/t/p/original/${link})`,
          backgroundPosition: 'center',
          opacity: '0.488',
          height: '282px',
        }}
      />
      <StyledFadeBottom />
    </StyledBannerDiv>
  )
}

export default Banner
