/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from 'react'
import EasterEgg from './easterEgg'
import { HistoryIcon } from '../Icons'
import styled from 'styled-components'
import { useSnackbar } from 'notistack'
import genres from '../../utils/genres'
import API from '../../client/API-client'
import ConfirmModal from '../ConfirmModal'
import { base_url } from '../../utils/helper'
import EmptyStateProfiles from './emptyState'
import { useLocation } from 'react-router-dom'
import showClient from '../../client/show-client'
import PlaceHolder from '../../assets/images/placeholder.png'
import { StyledRowCard, StyledRowCardMedia } from '../../utils/styled'
import { Loop, HighlightOff } from '@mui/icons-material'
import {
  Typography,
  Button,
  Grid,
  Skeleton,
  Tooltip,
  CircularProgress,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
} from '@mui/material'
import CardInfo from './card-info'
import { MovieceptionContext } from '../../context/MovieceptionContext'

const StyledMainDiv = styled.div`
  transition: opacity ease-in-out 0.8s;
`
const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0rem 2rem 0rem;
`
const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`
const StyledHighlightOff = styled(HighlightOff)`
  position: relative;
  top: -460px;
  left: 17rem;
  z-index: 1 !important;
`
const AddToWatchedHistoryIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  gap: 5;
`

const Row = ({
  movies,
  shows,
  episodes,
  title,
  addToWatched,
  user,
  refresh,
  setRefresh,
  setLoading,
  loading,
  setWatchedEpisodes,
}) => {
  const [info, setInfo] = useState([])
  const [genre, setGenre] = useState(0)
  const [opacity, setOpacity] = useState(0)
  const [updateArr, setUpdateArr] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [information, setInformation] = useState({})
  const [genreOpacity, setGenreOpacity] = useState(0)
  const [finishedShows, setAllFinishedShows] = useState()
  const [loadingGenres, setLoadingGenres] = useState(false)
  const [sumOfAllWatchedEpisodes, setSumOfAllWatchedEpisodes] = useState(0)

  const { refetch } = useContext(MovieceptionContext)

  const { enqueueSnackbar } = useSnackbar()
  // const navigate = useNavigate()

  const search = useLocation().search
  const genreUrl = new URLSearchParams(search).get('genre')
  const matches = useMediaQuery('(min-width:1932px)')

  useEffect(() => {
    setTimeout(() => {
      setOpacity('1')
    }, 200)
  }, [])

  useEffect(() => {
    setGenreOpacity('0')
    setTimeout(() => {
      setGenreOpacity('1')
    }, 600)
  }, [loadingGenres])

  useEffect(() => {
    if (movies) {
      movies?.map((movie) => {
        movie.isMovie = true
      })
      setInfo(movies)
    } else if (shows) {
      shows?.map((show) => {
        show.isMovie = false
        if (!show.NumberOfEpisodes && !show.NumberOfSeasons && title === 'Watched TV Shows') {
          showClient.getShow(show.id).then(
            (res) =>
              res &&
              setUpdateArr((prevState) => {
                const tempObj = [...prevState]
                tempObj.push({
                  username: user?.username,
                  ShowId: res.data.id,
                  NumberOfEpisodes: res.data.number_of_episodes,
                  NumberOfSeasons: res.data.number_of_seasons,
                })
                return tempObj
              })
          )
        }
      })
      setInfo(shows)
    } else {
      setInfo([])
    }
  }, [movies, shows, title])

  useEffect(() => {
    if (!updateArr?.length) return
    if (title === 'Watched TV Shows') {
      API.updateShowFromWatched(updateArr)
        .then((res) => !res && refetch())
        .finally(() => refetch())
    }
  }, [updateArr])

  useEffect(() => {
    if (!episodes) return
    setInfo((prevInfo) => {
      const newInfo = [...prevInfo]
      // eslint-disable-next-line array-callback-return
      newInfo.map((x) => {
        const index = episodes?.findIndex((y) => y.show_id === +x.id)
        if (index > -1) {
          let seasonNumbers = []
          let watchedEpisodes = []
          // eslint-disable-next-line array-callback-return
          episodes[index]?.seasons?.map((x) => {
            seasonNumbers.push(x.season_id)
            watchedEpisodes.push(x.episodes.length)
          })
          x.seasons = episodes[index]?.seasons
          x.watchedSeasons = episodes[index]?.seasons?.length
          x.seasonNumbers = seasonNumbers.sort((a, b) => a - b)
          const sum = watchedEpisodes.reduce((a, b) => {
            return a + b
          }, 0)
          x.totalWatchedEpisodes = sum
          x.rewatch_count = episodes[index].rewatch_count || 1
          x.finishedShow = x.NumberOfSeasons === episodes[index]?.seasons?.length && x.NumberOfEpisodes === sum && true
        }
      })

      return newInfo
    })
  }, [episodes])

  useEffect(() => {
    let allWatchedEpisodes = []
    let allFinishedShows = 0

    info?.map((x) => {
      x.totalWatchedEpisodes && allWatchedEpisodes.push(x.totalWatchedEpisodes * x.rewatch_count)
      x.finishedShow && allFinishedShows++
    })

    setSumOfAllWatchedEpisodes(
      allWatchedEpisodes.reduce((a, b) => {
        return a + b
      }, 0)
    )
    setAllFinishedShows(allFinishedShows)
  }, [info])

  useEffect(() => {
    if (!genreUrl) return
    handleGenre(+genreUrl)
  }, [genreUrl])

  const random = () => {
    const randomIndex = Math.floor(Math.random() * info.length)
    redirect(randomIndex)
  }

  const remove = (index) => {
    setModalOpen(true)
    setInformation({
      list: title,
      id: info[index].id,
      title: info[index].title,
      isMovie: info[index].isMovie,
    })
  }

  const removeForReal = () => {
    let remove
    if (information.isMovie) {
      // eslint-disable-next-line default-case
      switch (information.list) {
        case 'Favorite Movies':
          remove = API.removeMovieFromFavorites(user?.username, information.id)
          break
        case 'Movie WatchList':
          remove = API.removeMovieFromWatchList(user?.username, information.id)
          break
        case 'Watched Movies':
          remove = API.removeMovieFromWatched(user?.username, information.id)
          break
      }
    } else {
      // eslint-disable-next-line default-case
      switch (information.list) {
        case 'Favorite TV Shows':
          remove = API.removeShowFromFavorites(user?.username, information.id)
          break
        case 'TV Show Watchlist':
          remove = API.removeShowFromWatchList(user?.username, information.id)
          break
        case 'Watched TV Shows':
          remove = API.removeShowFromWatched(user?.username, information.id)
          break
        case 'Watched Episodes':
          remove = API.removeAllEpisodesFromWatched(user?.username, information.id, information.title, information.season)
          break
      }
    }

    setDisabled(true)
    setLoading(true)
    if (!remove) {
      setTimeout(() => {
        setLoading(false)
      }, 500)
      setDisabled(false)
    }
    remove
      .then((res) => {
        if (res.status === 200) {
          setRefresh(!refresh)
          enqueueSnackbar('Successful!', {
            variant: 'success',
          })
        }
      })
      .catch((err) => {
        console.log(err)
        setRefresh(!refresh)
        setTimeout(() => {
          setLoading(false)
        }, 500)
        setDisabled(false)
        setModalOpen(false)
        enqueueSnackbar('Something went wrong, please try again later!', {
          variant: 'error',
        })
      })
      .finally(() => {
        setRefresh(!refresh)
        refetch()
        setTimeout(() => {
          setLoading(false)
        }, 500)
        setDisabled(false)
        setModalOpen(false)
      })
  }

  const redirect = (idx) => {
    window.open(`/${info[idx].isMovie ? 'movies' : 'shows'}/${info[idx].id}/${info[idx].title}`, '_self')
  }

  const handleGenre = (id) => {
    setGenre(id)
    setLoadingGenres(true)
    movies?.map((m) => (m.isMovie = true))
    if (id === 0) {
      setInfo(movies)
      setTimeout(() => {
        setLoadingGenres(false)
      }, 300)
      return
    }
    setInfo(movies?.filter((i) => i.genres?.some((x) => x.id === id)))
    setTimeout(() => {
      setLoadingGenres(false)
    }, 300)
  }

  return (
    <>
      {!loading && (!info || !info?.length) ? (
        <EmptyStateProfiles />
      ) : (
        <StyledMainDiv style={{ opacity: opacity }}>
          {info?.length >= 5 && (
            <StyledDiv>
              <Button disabled={disabled} variant='outlined' color='info' onClick={random}>
                Random
                <Loop />
              </Button>
            </StyledDiv>
          )}
          {sumOfAllWatchedEpisodes > 0 && (
            <EasterEgg sumOfAllWatchedEpisodes={sumOfAllWatchedEpisodes} finishedShows={finishedShows} />
          )}
          {title === 'Movie WatchList' && matches && (
            <>
              <Grid container justifyContent='center'>
                {genres?.map((g) => (
                  <div
                    key={g.id}
                    style={{
                      padding: '6px',
                      cursor: 'pointer',
                      marginBottom: '10px',
                    }}
                    onClick={() => handleGenre(g.id)}
                  >
                    <Typography
                      component={'span'}
                      variant='body2'
                      sx={{
                        color: 'lightCoral',
                        padding: '0px 8px 4px 8px',
                        transition: 'color .3s ease-in-out',
                        '&: hover': { cursor: 'pointer', color: 'yellow' },
                        borderBottom: g.id === genre && '2.5px solid lightCoral',
                      }}
                      onClick={() => handleGenre(g.id)}
                    >
                      {g.name.toUpperCase()}
                    </Typography>
                  </div>
                ))}
              </Grid>
              <Typography
                container={'span'}
                variant='body2'
                sx={{
                  fontStyle: 'italic',
                  textAlign: 'center',
                  mt: 1,
                  mb: -2,
                }}
              >
                {info.length} {info.length > 1 ? 'Movies' : 'Movie'}
              </Typography>
            </>
          )}
          {title === 'Movie WatchList' && !matches && (
            <Grid container flexDirection='column' alignItems='center'>
              <FormControl sx={{ width: 128 }}>
                <InputLabel id='demo-simple-select-label'>Genre</InputLabel>
                <Select labelId='demo-simple-select-label' id='demo-simple-select' value={genre} label='Age'>
                  {genres?.map((g) => (
                    <MenuItem onClick={() => handleGenre(g.id)} value={g.id}>
                      {g.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography
                container={'span'}
                variant='body2'
                sx={{
                  fontStyle: 'italic',
                  textAlign: 'center',
                  mt: 1,
                  mb: -2,
                }}
              >
                {info.length} {info.length > 1 ? 'Movies' : 'Movie'}
              </Typography>
            </Grid>
          )}
          {loadingGenres ? (
            <StyledDiv style={{ marginTop: '1.7rem' }}>
              <CircularProgress size={50} />
            </StyledDiv>
          ) : (
            <Grid container justifyContent='center' spacing={5} sx={{ mt: 1 }}>
              {info?.map((movie, idx) => {
                return (
                  (movie?.poster_path || movie?.backdrop_path || movie?.profile_path) && (
                    <Grid item key={idx} sx={{ opacity: genreOpacity, transition: 'opacity 1s ease-in-out' }}>
                      <StyledRowCard
                        sx={{
                          maxWidth: 312,
                          minHeight: 550,
                          transition: 'box-shadow .7s ease-in-out',
                          '&: hover': {
                            boxShadow: '0 3px 8px 0 rgba(255, 127, 80, 0.8), 0 1px 30px 0 rgba(255, 127, 80, 0.9)',
                          },
                        }}
                      >
                        {!(movie?.poster_path || movie?.backdrop_path || movie?.profile_path) ? (
                          <Skeleton variant='rectangular' width={312} height={468} />
                        ) : (
                          <>
                            <Link href={`/${info[idx].isMovie ? 'movies' : 'shows'}/${info[idx].id}/${info[idx].title}`}>
                              <StyledRowCardMedia
                                sx={[{ height: 468, width: 312, backgroundSize: 'contain' }]}
                                image={movie?.poster_path ? `${base_url}/${movie?.poster_path}` : PlaceHolder}
                              />
                            </Link>
                            <StyledTooltip title='Remove'>
                              <StyledHighlightOff fontSize='large' onClick={() => remove(idx)} />
                            </StyledTooltip>
                          </>
                        )}
                        <CardInfo
                          idx={idx}
                          user={user}
                          info={info}
                          movie={movie}
                          title={title}
                          setLoading={setLoading}
                          setWatchedEpisodes={setWatchedEpisodes}
                        />
                        <AddToWatchedHistoryIcon>
                          {addToWatched && <HistoryIcon setRefresh={setRefresh} movie={movie} user={user} refetch={refetch} />}
                        </AddToWatchedHistoryIcon>
                      </StyledRowCard>
                    </Grid>
                  )
                )
              })}
            </Grid>
          )}
        </StyledMainDiv>
      )}
      <ConfirmModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        information={information}
        removeForReal={removeForReal}
        setInformation={setInformation}
      />
    </>
  )
}

export default Row
