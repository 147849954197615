import React, { useState, useEffect } from 'react'
import { CardMedia, Modal } from '@mui/material'
import styled from 'styled-components'

const StyledDiv = styled.div`
    position: relative;
    width: 75%;
    padding-bottom: 56.25%;
`
const StyledCardMedia = styled(CardMedia)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 85%;
    border: none;
`

function Trailer({ videos, show, handleClose }) {
  const [trailerUrl, setTrailerUrl] = useState('')

  useEffect(() => {
    if (!videos) return
    const officialTrailer = videos.results.filter(x => x.type === 'Trailer' && (x.name.includes('Official') || x.name.includes('Main')))
    const trailerVideos = videos.results.filter(e => e.type === 'Trailer')

    if (officialTrailer) {
      setTrailerUrl(officialTrailer[0]?.key)
      return
    } else if (trailerVideos) {
      setTrailerUrl(trailerVideos[0]?.key)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  return (
    <Modal open={show} onClose={handleClose} style={modalStyle}>
      <StyledDiv>
        <StyledCardMedia
          component='iframe'
          src={`https://www.youtube.com/embed/${trailerUrl}`}
        />
      </StyledDiv>
    </Modal>
  )
}

export default Trailer
