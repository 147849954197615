import React, { useState } from 'react'
import Row from '../../components/Row'
import movieRoutes from '../../client/movie-client'

const MarvelMovies = () => {
  document.title = 'Marvel'
  const [currentPage, setCurrentPage] = useState(1)

  return (
    <div>
      <Row
        fetchUrl={movieRoutes.getMarvelMovies(currentPage)}
        title={'Marvel Movies'}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        marvel={true}
      />
    </div>
  )
}

export default MarvelMovies