import { useState, useEffect } from 'react'
import { Button, Link } from '@mui/material'
import { Carousel } from 'react-bootstrap'
import { Loop } from '@mui/icons-material'
import styled from 'styled-components'

const StyledImgDiv = styled.div`
  transition: opacity ease-in-out 0.8s;
  height: 786px;
  background-size: cover;
  background-position: 50% 10%;
  ${(props) => `
    background-image: url(https://image.tmdb.org/t/p/original${props.url})
  `
  };
`
const StyledCarousel = styled(Carousel)`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  margin: -5rem -10rem 0rem -10rem;
  @media (max-width: 600px) {
    margin: -3rem -3rem 0rem -3rem;
  }
`
const StyledMovieNameContainerDiv = styled.div`
  font-style: italic;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
const SpanDiv = styled.div`
  text-decoration: underline;
  color: rgb(205, 191, 191);
  cursor: pointer;
  padding-left: 0.4rem;
`
const StyledH1 = styled.h1`
  position: relative;
  bottom: 30rem;
  @media (max-width: 500px) {
    bottom: 28rem;
    font-size: 1.8rem;
  }
`
const StyledHomeRandomDiv = styled.div`
  margin-bottom: 1rem;
`

const CarouselComponent = ({ trendingMovie, user, greet, setRandomModalOpen }) => {
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setOpacity('0.6')
    }, 800)
  }, [])

  useEffect(() => {
    if (!trendingMovie) return
    trendingMovie.map(m => (
      m.href = `/${m.media_type === 'tv' ? 'shows' : 'movies'}/${m.id}/${m?.title || m?.original_title || m?.name || m?.original_name}`
    ))
  }, [trendingMovie])
  return (
    trendingMovie && (
      <StyledCarousel
        interval={12000}
        prevLabel=''
        nextLabel=''
        indicators={false}
        fade
      >
        {trendingMovie?.map((movie, idx) => (
          <Carousel.Item key={idx}>
            <StyledImgDiv
              style={{ opacity: opacity }}
              url={movie?.backdrop_path}
            />
            <Carousel.Caption>
              <div>
                <StyledH1>
                  {user?.username && `${greet}, ${user?.username ? user?.username.split(' ')[0] : user?.username}!`}
                  {!user?.username && `${greet}, welcome to Movieception!`}
                </StyledH1>
              </div>
              <StyledHomeRandomDiv>
                <Button variant='outlined' onClick={() => setRandomModalOpen(true)}>
                  Random
                  <Loop />
                </Button>
              </StyledHomeRandomDiv>
              <StyledMovieNameContainerDiv>
                <div>Background Image From:</div>
                <Link href={movie.href}>
                  <SpanDiv                  >
                    {movie.title || movie.name}
                  </SpanDiv>
                </Link>
              </StyledMovieNameContainerDiv>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </StyledCarousel>
    )
  )
}

export default CarouselComponent