import React from 'react'
import { useEffect, useState } from 'react'
import { Tooltip } from '@mui/material'
import styled from 'styled-components'

const StyledScrollDiv = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 4rem 1rem;
  z-index: 10;
  background-color: rgb(116, 15, 15);
  padding: .8rem;
  border-radius: 15px;
  transition: 0.6s all ease-in-out;
  &: hover {
    cursor: pointer
  }
`

function ScrollToBottom({ user }) {
  const [isVisible, setIsVisible] = useState(false)

  const isProfile = window.location.href.includes('/profile')

  useEffect(() => {
    if (isProfile && user?.username) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }, [isProfile, user])

  const scrollToBottomFunc = () => {
    window.scrollTo({
      top: 10000000,
      behavior: 'smooth',
    })
  }

  return (
    <div>
      <Tooltip arrow title='Scroll Down'>
        <StyledScrollDiv style={{ transform: `scale(${isVisible ? 1 : 0})` }} onClick={() => scrollToBottomFunc()}>
          <i className='top-btn fas fa-chevron-circle-down' onClick={() => scrollToBottomFunc()}>
            <span onClick={() => scrollToBottomFunc()}></span>
          </i>
        </StyledScrollDiv>
      </Tooltip>
    </div>
  )
}

export default ScrollToBottom
