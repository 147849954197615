import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { StyledCircularProgressDiv } from '../../utils/styled'
import { ProfileRow, NotLoggedIn, UpperProfile } from '../../components'
import { CircularProgress, Typography, Tabs, Tab, Box } from '@mui/material'

const Profile = ({ user }) => {
  const [tab, setTab] = useState(0)
  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)
  const [watchedShows, setWatchedShows] = useState([])
  const [favoriteShows, setFavoriteShows] = useState([])
  const [showWatchList, setShowWatchList] = useState([])
  const [watchedMovies, setWatchedMovies] = useState([])
  const [favoriteMovies, setFavoriteMovies] = useState([])
  const [movieWatchList, setMovieWatchList] = useState([])
  const [watchedEpisodes, setWatchedEpisodes] = useState([])

  const navigate = useNavigate()

  const search = useLocation().search
  useEffect(() => {
    setTab(new URLSearchParams(search).get('tab'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = () => {
      if (!user?.username) return
      setLoading(true)
      setFavoriteMovies(user.movieFavorites)
      setFavoriteShows(user.showFavorites)
      setMovieWatchList(user.movieWatchList)
      setShowWatchList(user.showWatchList)
      setWatchedMovies(user.movieHistory)
      setWatchedShows(user.showHistory)
      setWatchedEpisodes(user.episodes)
      setValue(+tab)
      setTimeout(() => {
        setLoading(false)
      }, 700)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleChange = (event, newValue) => {
    setValue(newValue)
    navigate(`/profile?tab=${newValue}`)
    setTab(newValue)
  }

  const CustomTabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  // make sure page is loaded on top
  // window.onbeforeunload = () => {
  //   window.scrollTo(0, 0);
  // }

  return (
    <>
      {!user?.username ? (
        !loading && <NotLoggedIn />
      ) : (
        <>
          {loading ? (
            <StyledCircularProgressDiv>
              <CircularProgress size={70} />
            </StyledCircularProgressDiv>
          ) : (
            <>
              <div>
                <UpperProfile user={user} />
              </div>
              <Box
                sx={{ borderBottom: 1, borderColor: 'divider' }}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <Tabs
                  value={value}
                  variant="scrollable"
                  onChange={handleChange}
                  allowScrollButtonsMobile
                  sx={{
                    "& button": { color: 'coral', transition: 'color .6s ease-in-out' },
                    "& button:hover": { color: 'yellow' },
                    ".MuiTab-root.Mui-selected": { color: 'yellow' }
                  }}
                  TabIndicatorProps={{
                    sx: {
                      transition: 'background-color 1s ease-in-out',
                      backgroundColor: "yellow",
                    }
                  }}
                >
                  <Tab style={{ outline: 'none' }} label={`Watched TV Shows (${watchedShows?.length ?? 0})`} />
                  <Tab style={{ outline: 'none' }} label={`Favorite TV Shows (${favoriteShows?.length ?? 0}) `} />
                  <Tab style={{ outline: 'none' }} label={`TV Show WatchList (${showWatchList?.length ?? 0}) `} />
                  <Tab style={{ outline: 'none' }} label={`Watched Movies (${watchedMovies?.length ?? 0}) `} />
                  <Tab style={{ outline: 'none' }} label={`Favorite Movies (${favoriteMovies?.length ?? 0}) `} />
                  <Tab style={{ outline: 'none' }} label={`Movie WatchList (${movieWatchList?.length ?? 0}) `} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <ProfileRow
                  title='Watched TV Shows'
                  user={user}
                  shows={watchedShows}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  loading={loading}
                  setLoading={setLoading}
                  episodes={watchedEpisodes}
                  setWatchedEpisodes={setWatchedEpisodes}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <ProfileRow
                  title='Favorite TV Shows'
                  shows={favoriteShows}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  setLoading={setLoading}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <ProfileRow
                  title='TV Show Watchlist'
                  shows={showWatchList}
                  addToWatched
                  user={user}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  setLoading={setLoading}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <ProfileRow
                  title='Watched Movies'
                  movies={watchedMovies}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  setLoading={setLoading}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <ProfileRow
                  title='Favorite Movies'
                  addToWatched
                  user={user}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  setLoading={setLoading}
                  movies={favoriteMovies}
                />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={5}>
                <ProfileRow
                  title='Movie WatchList'
                  addToWatched
                  user={user}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  setLoading={setLoading}
                  movies={movieWatchList}
                />
              </CustomTabPanel>
            </>
          )}
        </>
      )}
    </>
  )
}

export default Profile