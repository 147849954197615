import React, { useState } from 'react'
import Row from '../../components/Row'
import showRoutes from '../../client/show-client'

function Shows() {
  document.title = 'TV Shows'
  const [currentPage, setCurrentPage] = useState(1)

  return (
    <div className='show'>
      <Row
        title={'Popular Shows'}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        fetchUrl={showRoutes.getTrending(currentPage)}
      />
    </div>
  )
}

export default Shows
