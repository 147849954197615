export const links = [
    {
        title: 'Home',
        href: '/home'
    },
    {
        title: 'Discover',
        href: '/discover'
    },
    {
        title: 'Movies',
        href: '/movies'
    },
    {
        title: 'Shows',
        href: '/shows'
    },
    {
        title: 'Actors',
        href: '/actors'
    },
    {
        title: 'Netflix',
        href: '/netflix'
    },
    {
        title: 'About',
        href: '/about'
    },
    {
        title: 'Login'
    },
]
export const avatarLinks = [
    {
        title: 'Profile',
        href: '/profile?tab=0'
    },
    {
        title: 'Settings',
        href: '/settings'
    },
    {
        title: 'Logout'
    }
]