import React from "react"
import { Modal, Button, Typography } from '@mui/material'
import { StyledContainerForSearchModal } from "../../utils/styled"

function ConfirmModal({ open, handleClose, information, setInformation, removeForReal }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <StyledContainerForSearchModal maxWidth='md'>
        {!information.season ? (
          <Typography variant="h6">Are you sure to remove {information.title} from your {information.list}?</Typography>
        ) : (
          <Typography variant="h6">Are you sure to remove entire Season {information.season} from {information.list}?</Typography>
        )}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1rem',
          marginTop: '2rem'
        }}>
          <Button variant='outlined' onClick={() => { handleClose(); setInformation({}) }}>Close</Button>
          <Button variant='outlined' color='error' onClick={removeForReal}>Remove</Button>
        </div>
      </StyledContainerForSearchModal>
    </Modal>
  )
}

export default ConfirmModal
