import React, { useState } from 'react'
import Row from '../../components/Row'
import movieRoutes from '../../client/movie-client'

const Netflix = () => {
  document.title = 'Netflix'
  const [currentPage, setCurrentPage] = useState(1)

  return (
    <div>
      <Row
        fetchUrl={movieRoutes.getNetflixOriginal(currentPage)}
        title={'Netflix Shows'}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  )
}

export default Netflix