import axiosConfig from '../axios'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getPopular: () => {
    return axiosConfig.get('/api/v1.0/actors/popular')
  },
  getSearchActor: (query) => {
    return axiosConfig.get(`/api/v1.0/actors/${query}search`)
  },
  getActor: (ActorId) => {
    return axiosConfig.get(`/api/v1.0/actors/${ActorId}`)
  },
  getImages: (ActorId) => {
    return axiosConfig.get(`/api/v1.0/actors/${ActorId}/images`)
  },
  getExternalIds: (ActorId) => {
    return axiosConfig.get(`/api/v1.0/actors/${ActorId}/external-ids`)
  },
  getCredits: (ActorId) => {
    return axiosConfig.get(`/api/v1.0/actors/${ActorId}/credits`)
  },
}