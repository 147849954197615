import React, { useState, useEffect } from 'react'
import Banner from './banner'
import styled from 'styled-components'
import { Avatar, Badge, Typography, Tooltip } from '@mui/material'
import { Edit } from '@mui/icons-material'

const StyledMainDiv = styled.div`
  margin-bottom: -10rem;
  transition: opacity ease-in-out 0.8s;
`
const StyledAvatarDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  bottom: 14rem;
`
const StyledAvatar = styled(Avatar)`
  padding: 0.4rem;
  transition: opacity ease-in-out 1s;
`
const StyledEditIcon = styled(Edit)`
  &: hover {
    cursor: pointer;
  }
`
export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const stringAvatar = (name) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name?.split(' ')[0][0].toUpperCase()}`,
  };
}

const UpperProfile = ({ user }) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric"
  }
  const memberSince = new Date(user?.createdAt).toLocaleDateString('en-US', options)
  const settingsPage = window.location.href.includes('/settings')

  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setOpacity('1')
    }, 400)
  }, [])

  return (
    <StyledMainDiv
      style={{ opacity: opacity }}
    >
      <Banner user={user} />
      <StyledAvatarDiv>
        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          badgeContent={
            <>
              {settingsPage &&
                <Tooltip arrow title='Edit Avatar & Banner, coming soon'>
                  {/* TODO: Edit avatar modal */}
                  <StyledEditIcon />
                </Tooltip>
              }
            </>
          }
        >
          <div style={{ border: '4px solid coral', borderRadius: '50%' }}>
            <StyledAvatar
              style={{ width: 172, height: 172, fontSize: '4.5rem' }}
              // src='/banner.jpg'
              {...stringAvatar(`${user?.username}`)}
            />
          </div>
        </Badge>
        <Typography variant='h5'>
          {user?.username}
        </Typography>
        {user?.username && user?.role !== 'user' &&
          <Typography variant='body2'>
            {`${user?.username}${` - ${user.role}`}`}
          </Typography>
        }
        {memberSince &&
          <Typography variant='body2'>
            Movieceptionite since {memberSince}
          </Typography>
        }
      </StyledAvatarDiv>
    </StyledMainDiv>
  )
}

export default UpperProfile