import React, { useState, useEffect } from 'react'
import { base_url } from '../../utils/helper'
import {
  StyledSeasonsPosters,
  StyledSeasonsMap,
  StyledSeasonsName,
  StyledSeasonsPoster,
  StyledSeasonsEpisodes
} from '../../utils/styled'
import PlaceHolder from '../../assets/images/placeholder.png'

function Seasons({ show }) {
  const [season, setSeasons] = useState([])

  useEffect(() => {
    setSeasons(show?.seasons)
  }, [show])

  const hasSpecials = season[0]?.name === 'Specials'

  const redirect = (x) => {
    window.location.assign(
      `/shows/${show?.id}/season?season_number=${x?.season_number === 0 ? 'specials' : x?.season_number}&total=${hasSpecials ? season?.length - 1 : season?.length
      }&has_specials=${hasSpecials}`
    )
  }

  return (
    <div>
      {season && (
        <div>
          <div style={{ textAlign: 'center', marginTop: '2rem' }}>
            {hasSpecials ? season?.length - 1 : season?.length} Seasons
          </div>
          <StyledSeasonsPosters>
            {season?.map(
              (x) =>
                <StyledSeasonsMap key={x.id}>
                  <StyledSeasonsPoster
                    onClick={() => redirect(x)}
                    src={x?.poster_path ? `${base_url}${x?.poster_path}` : PlaceHolder}
                    alt={x?.name}
                  />
                  <StyledSeasonsName>{x?.name}</StyledSeasonsName>
                  <StyledSeasonsEpisodes>{x?.episode_count} Episodes</StyledSeasonsEpisodes>
                </StyledSeasonsMap>
            )}
          </StyledSeasonsPosters>
        </div>
      )}
    </div>
  )
}

export default Seasons
