/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import APIClient from '../../client/API-client'
import { StyledCircularProgressDiv } from '../../utils/styled'
import { CircularProgress, Typography } from '@mui/material'
import { UpperProfile } from '../../components'
import UserComponents from '../../components/UserComponents'

const User = () => {
  const [info, setInfo] = useState([])
  const [opacity, setOpacity] = useState(0)
  const [userData, setUserData] = useState()
  const [loading, setLoading] = useState(true)
  const [episodes, setWatchedEpisodes] = useState([])
  const [watchedShows, setWatchedShows] = useState([])
  const [favoriteShows, setFavoriteShows] = useState([])
  const [showWatchList, setShowWatchList] = useState([])
  const [watchedMovies, setWatchedMovies] = useState([])
  const [favoriteMovies, setFavoriteMovies] = useState([])
  const [movieWatchList, setMovieWatchList] = useState([])
  const [finishedShows, setAllFinishedShows] = useState()
  const [sumOfAllWatchedEpisodes, setSumOfAllWatchedEpisodes] = useState(0)
  const { Username } = useParams()

  setTimeout(() => {
    setOpacity(1)
  }, 1500)

  useEffect(() => {
    setLoading(true)
    APIClient.getUser(Username)
      .then(res => {
        if (res) return setUserData(res.data)
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = () => {
      if (!userData?.username) return
      setLoading(true)
      setFavoriteMovies(userData?.movieFavorites)
      setFavoriteShows(userData?.showFavorites)
      setMovieWatchList(userData?.movieWatchList)
      setShowWatchList(userData?.showWatchList)
      setWatchedMovies(userData?.movieHistory)
      setWatchedShows(userData?.showHistory)
      setWatchedEpisodes(userData?.episodes)
      setInfo(userData?.showHistory || [])
      setTimeout(() => {
        setLoading(false)
      }, 700)
    }
    fetchData()
  }, [userData])

  useEffect(() => {
    if (!episodes) return
    setInfo(prevInfo => {
      const newInfo = [...prevInfo]
      // eslint-disable-next-line array-callback-return
      newInfo.map(x => {
        const index = episodes?.findIndex(y => y.show_id === +x.id)
        if (index > -1) {
          let seasonNumbers = []
          let watchedEpisodes = []
          // eslint-disable-next-line array-callback-return
          episodes[index]?.seasons?.map(x => {
            seasonNumbers.push(x.season_id)
            watchedEpisodes.push(x.episodes.length)
          })
          x.seasons = episodes[index]?.seasons
          x.watchedSeasons = episodes[index]?.seasons?.length
          x.seasonNumbers = seasonNumbers.sort((a, b) => a - b)
          const sum = watchedEpisodes.reduce((a, b) => { return a + b }, 0)
          x.totalWatchedEpisodes = sum
          x.rewatch_count = episodes[index].rewatch_count || 1
          x.finishedShow = x.NumberOfSeasons === episodes[index]?.seasons?.length && true
        }
      })

      return newInfo
    })

  }, [episodes])

  useEffect(() => {
    let allWatchedEpisodes = []
    let allFinishedShows = 0

    info?.map(x => {
      x.totalWatchedEpisodes && allWatchedEpisodes.push(x.totalWatchedEpisodes * x.rewatch_count)
      x.finishedShow && allFinishedShows++
    })

    setSumOfAllWatchedEpisodes(allWatchedEpisodes.reduce((a, b) => { return a + b }, 0))
    setAllFinishedShows(allFinishedShows)
  }, [info])

  return (
    <>
      {!userData && <Typography sx={{ textAlign: 'center', opacity: opacity, transition: 'opacity 1s ease-in-out' }} variant='h4'>No user found with that username, click <a href='/'>here</a> to go back home!</Typography>}
      {userData && !userData?.isProfilePublic && <Typography variant='h4' style={{ textAlign: 'center', marginTop: '5rem', opacity: opacity, transition: 'opacity 1s ease-in-out' }}>{Username} has made their profile private.</Typography>}
      {(loading && userData?.isProfilePublic) ? (
        <StyledCircularProgressDiv>
          <CircularProgress size={70} />
        </StyledCircularProgressDiv>
      ) : userData?.isProfilePublic && (
        <>
          <>
            <div>
              <UpperProfile user={userData} />
            </div>
            <UserComponents
              watchedShows={watchedShows}
              favoriteShows={favoriteShows}
              showWatchList={showWatchList}
              watchedMovies={watchedMovies}
              favoriteMovies={favoriteMovies}
              movieWatchList={movieWatchList}
              finishedShows={finishedShows}
              sumOfAllWatchedEpisodes={sumOfAllWatchedEpisodes}
            />
          </>
        </>
      )}
    </>
  )
}

export default User