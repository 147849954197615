import React from 'react'

const NoResultPage = () => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <div>
        Something went wrong, we are working on it and we'll be right back!
      </div>
    </div>
  )
}

export default NoResultPage