import React from 'react'
import People from '../../components/People'
import actorRoutes from '../../client/actor-client'

function Actors() {
  document.title = 'Actors'

  return (
    <div className='movie'>
      <People
        fetchUrl={actorRoutes.getPopular()}
        title='Popular Actors'
      />
    </div>
  )
}

export default Actors
