import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import Banner from '../../components/Banner'
import { useTitle } from '../../hooks/useTitle'
import { CircularProgress } from '@mui/material'
import Overview from '../../components/Overview'
import Episodes from '../../components/Episodes'
import showRoutes from '../../client/show-client'
import { StyledCollectionContainer, StyledCollectionImage, StyledCollectionImageContainer, StyledCollectionMainDiv } from '../../utils/styled'

function SeasonPage({ user }) {
  const [show, setShow] = useState({})
  const [showPosterPath, setShowPosterPath] = useState()
  const [title, setTitle] = useState()
  const [loading, setLoading] = useState(false)
  const [documentTitle, setDocumentTitle] = useTitle()
  const { ShowId } = useParams()

  const search = useLocation().search
  const seasonNumber = new URLSearchParams(search).get('season_number')
  const totalSeasons = new URLSearchParams(search).get('total')
  const hasSpecials = new URLSearchParams(search).get('has_specials')
  let SeasonId
  seasonNumber === 'specials' ? (SeasonId = 0) : (SeasonId = seasonNumber)

  useEffect(() => {
    document.title = documentTitle ?? 'Movieception'
  }, [documentTitle])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      showRoutes.getShow(ShowId)
        .then(res => {
          if (!res) return
          setTitle(res.data.original_name)
          const hasSpecial = res.data.seasons.some((x) => x.name === 'Specials')
          setShow(res.data.seasons[hasSpecial ? SeasonId : SeasonId - 1])
          setDocumentTitle(res.original_title || res.title || res.name)
          setShowPosterPath(res.data?.poster_path)
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
        .finally(() => setLoading(false))
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SeasonId, ShowId])

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '10rem',
          }}
        >
          <CircularProgress size={70} />
        </div>
      ) : (
        <>
          {show?.poster_path && <Banner link={show?.poster_path} />}
          <StyledCollectionMainDiv>
            <StyledCollectionContainer>
              <StyledCollectionImageContainer>
                <StyledCollectionImage
                  src={`https://image.tmdb.org/t/p/original${show?.poster_path}`}
                />
              </StyledCollectionImageContainer>
              <Overview link={show} />
              <Episodes
                show={show}
                ShowId={ShowId}
                SeasonId={SeasonId}
                user={user}
                ShowTitle={title}
                hasSpecials={hasSpecials}
                totalSeasons={totalSeasons}
                showPosterPath={showPosterPath}
              />
            </StyledCollectionContainer>
          </StyledCollectionMainDiv>
        </>
      )}
    </div>
  )
}

export default SeasonPage
