import App from './App'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { SnackbarProvider } from "notistack"
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from "@auth0/auth0-react"
import './index.css'
import { MovieceptionContextProvider } from './context/MovieceptionContext'

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

const queryClient = new QueryClient()
queryClient.setDefaultOptions({
  queries: {
    staleTime: 180000,
    refetchOnWindowFocus: false
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename='/'>
    <SnackbarProvider>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
          redirect_uri: redirectUri,
        }}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      >
        <QueryClientProvider client={queryClient}>
          <MovieceptionContextProvider>
            <App />
          </MovieceptionContextProvider>
        </QueryClientProvider>
      </Auth0Provider>
    </SnackbarProvider>
  </BrowserRouter>
)
