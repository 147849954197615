import React, { useState } from 'react'
import styled from 'styled-components'
import { base_url } from '../../utils/helper'
import { ExpandLess, ExpandMore, OpenInNew, Folder, DeleteForever } from '@mui/icons-material'
import { AccordionDetails, ListItemButton, ListItemText, Collapse, List, ListItemAvatar, Avatar, ListItem } from '@mui/material'

const StyledOpenInNew = styled(OpenInNew)`
  margin-right: 0.5rem;
  &: hover {
    cursor: pointer
  }
  @media (max-width: 500px) {
    margin-right: 0;
  }
`
const StyledDeleteForever = styled(DeleteForever)`
  &: hover {
    cursor: pointer;
  }
`
const StyledListItem = styled(ListItem)`
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`

const ProfileAccordion = ({ episode, setInformation, setModalOpen }) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  const remove = (season) => {
    setModalOpen(true)
    setInformation({
      id: episode.show_id,
      title: episode.show_title,
      list: 'Watched Episodes',
      season: season
    })
  }

  return (
    <AccordionDetails>
      <div>
        <ListItemButton onClick={handleClick} divider>
          <ListItemAvatar>
            <Avatar>
              {episode.show_poster_path ? <Avatar src={`${base_url}/${episode.show_poster_path}`} /> : <Folder />}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={`${episode.show_title} - Watched ${episode.seasons.length} ${episode.seasons.length <= 1 ? 'season' : 'seasons'}`} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} unmountOnExit>
          <List component="div" disablePadding>
            {episode.seasons.map((s, idx) => (
              <div key={idx}>
                <StyledListItem>
                  <ListItemAvatar>
                    <Avatar>
                      {s.season_poster_path ? <Avatar src={`${base_url}/${s.season_poster_path}`} /> : <Folder />}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={`Season ${s.season_id} - Watched ${s.episodes.length} ${s.episodes.length === 1 ? 'episode' : 'episodes'}`} />
                  <div>
                    <StyledOpenInNew onClick={() => window.open(`/shows/${episode.show_id}/season?season_number=${s.season_id}&total=${s.episodes.length}`, '_blank')} />
                    <StyledDeleteForever onClick={() => remove(s.season_id)} />
                  </div>
                </StyledListItem>
              </div>
            ))}
          </List>
        </Collapse>
      </div>
    </AccordionDetails>
  )
}

export default ProfileAccordion