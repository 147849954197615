import React, { createContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import APIClient from '../client/API-client'
import { useAuth0 } from '@auth0/auth0-react'
import { auth } from '../hooks/auth'

const MovieceptionContext = createContext()

const MovieceptionContextProvider = ({ children }) => {
  const { user, isLoading, isAuthenticated, loginWithPopup, logout } = useAuth0()

  const { data: response, status, refetch } = useQuery(
    ['user'],
    () => {
      return APIClient.loggedIn(user.nickname)
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!user,
      retry: false
    }
  )

  useEffect(() => {
    if (status === 'idle' || status === 'loading' || isLoading) return
    if (!isLoading && isAuthenticated && response) return
    if (!user) return

    // creating a new user in db with auth0 credentials
    if (status === 'error' && !response) {
      auth(user)
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, isLoading, response, user])

  return (
    <MovieceptionContext.Provider
      value={{
        data: response?.data,
        isLoading,
        isAuthenticated,
        status,
        loginWithPopup,
        logout,
        user,
        refetch
      }}
    >
      {children}
    </MovieceptionContext.Provider>
  )

}

export { MovieceptionContext, MovieceptionContextProvider }