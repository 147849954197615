import { useNavigate } from 'react-router-dom'
import Buttons from '../Buttons'
import styled from 'styled-components'
import { Button } from '@mui/material'
import WatchProviders from '../WatchProviders'

const StyledDivCenter = styled.div`
text-align: center;
`

const StyledBottomSection = styled.div`
display: flex;
flex-wrap: wrap;
text-align: center;
align-items: center;
justify-content: center;
`

const StyledDivInBottomSection = styled.div`
padding: 0.2rem 0.5rem;
`
const StyledSpan = styled.span`
color: #b6894e;
`
const StyledLinkSpan = styled.span`
color: #b6894e;
text-decoration: underline;
&: hover {
    cursor: pointer;
}
`
const StyledButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.5rem;
`
const Overview = ({ link, user, videos, setTrailerModal, movie, show }) => {
    const navigate = useNavigate()

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
    }

    const date = new Date(link?.release_date || link?.first_air_date || link?.air_date)
    const nextEpisodeDate = new Date(link?.next_episode_to_air?.air_date)
    const lastEpisodeDate = new Date(link?.last_episode_to_air?.air_date)
    const isSeason = window.location.href.includes('season')

    return (
        <div>
            {!isSeason && user?.username && <Buttons user={user} movie={link} />}
            <h1 style={{ textAlign: 'center', paddingTop: '0.8rem' }}>{link?.title || link?.original_title || link?.name}</h1>

            <StyledDivCenter style={{ fontStyle: 'italic', padding: '12px 0px' }}>
                {link?.tagline && <div>{link?.tagline}</div>}
            </StyledDivCenter>

            <StyledDivCenter style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
                <StyledSpan>{link?.genres?.map(x => (x.name)).join(', ')}</StyledSpan>
            </StyledDivCenter>

            <div style={{ textAlign: 'center', marginBottom: '2rem', marginTop: '1rem' }}>
                {link?.overview && <div>{link?.overview}</div>}
            </div>
            <div style={{ marginBottom: '2rem' }}>
                {link?.last_episode_to_air && (
                    <div style={{ textAlign: 'center' }}>
                        Last Episode To Air:{' '}
                        <StyledSpan>
                            Season {link?.last_episode_to_air?.season_number} Episode {link?.last_episode_to_air?.episode_number}
                        </StyledSpan>{' '}
                        in <StyledSpan>{lastEpisodeDate.toLocaleString('en-US', options)}</StyledSpan>
                    </div>
                )}
                {link?.next_episode_to_air && (
                    <div style={{ textAlign: 'center' }}>
                        Next Episode To Air:{' '}
                        <StyledSpan>
                            Season {link?.next_episode_to_air?.season_number} Episode {link?.next_episode_to_air?.episode_number}
                        </StyledSpan>{' '}
                        in <StyledSpan>{nextEpisodeDate.toLocaleString('en-US', options)}</StyledSpan>
                    </div>
                )}
            </div>

            <div style={{ textAlign: 'center' }}>
                {link?.status && (
                    <div>
                        Status: <StyledSpan>{link?.status}</StyledSpan>
                    </div>
                )}
            </div>

            {/* Bottom section */}
            <StyledBottomSection>
                {date && (
                    <StyledDivInBottomSection>
                        Release Date: <StyledSpan>{date.toLocaleString('en-US', options)}</StyledSpan>
                    </StyledDivInBottomSection>
                )}
                {link?.original_language === 'en' && (
                    <StyledDivInBottomSection>
                        Language: <StyledSpan>English</StyledSpan>
                    </StyledDivInBottomSection>
                )}
                {link?.runtime > 0 && (
                    <StyledDivInBottomSection>
                        Runtime: <StyledSpan>{link?.runtime} minutes</StyledSpan>
                    </StyledDivInBottomSection>
                )}
                {link?.budget > 0 && (
                    <StyledDivInBottomSection>
                        Budget:{' '}
                        <StyledSpan>{link?.budget.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</StyledSpan>
                    </StyledDivInBottomSection>
                )}
                {link?.revenue > 0 && (
                    <StyledDivInBottomSection>
                        Revenue:{' '}
                        <StyledSpan>{link?.revenue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</StyledSpan>
                    </StyledDivInBottomSection>
                )}
                {link?.production_countries && (
                    <StyledDivInBottomSection>
                        {link?.production_countries.length === 1 ? 'Country:' : 'Countries:'} <StyledSpan>{link?.production_countries.map((x) => (x.name)).join(', ')}</StyledSpan>
                    </StyledDivInBottomSection>
                )}
                {/* add logos to companies later */}
                {link?.production_companies && (
                    <StyledDivInBottomSection>
                        {link?.production_companies.length === 1 ? 'Production Company:' : 'Production Companies:'} <StyledSpan>{link?.production_companies.map((x) => (x.name)).join(', ')}</StyledSpan>
                    </StyledDivInBottomSection>
                )}
                {link?.belongs_to_collection && (
                    <StyledDivInBottomSection>
                        Collection: <StyledLinkSpan onClick={() => navigate(`/movies/collections/${link.belongs_to_collection.id}`)} >{link?.belongs_to_collection?.name}</StyledLinkSpan>
                    </StyledDivInBottomSection>
                )}
                {link?.number_of_episodes && (
                    <StyledDivInBottomSection>
                        Total Episodes: <StyledSpan>{link?.number_of_episodes}</StyledSpan>
                    </StyledDivInBottomSection>
                )}
            </StyledBottomSection>
            {videos &&
                <StyledButtonDiv style={{ marginTop: '1rem' }}>
                    <Button variant='outlined' onClick={() => setTrailerModal(true)}><StyledSpan>Watch Trailer</StyledSpan></Button>
                </StyledButtonDiv>
            }
            <WatchProviders movie={movie} show={show} />
        </div>
    )
}

export default Overview
