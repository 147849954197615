import React, { useEffect, useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import { base_url } from '../../utils/helper'
import {
  StyledCreditsCastPosters,
  StyledCreditsCastsMap,
  StyledCreditsCastsName,
  StyledCreditsCastsEpisodes,
  StyledCreditsCastsCharacter,
  StyledCreditsCastsPosterImg,
  StyledMovieCreditsBtn,
  StyledCreditsGridDiv,
  StyledCircularProgressDiv
} from '../../utils/styled'
import actorRoutes from '../../client/actor-client'
import PlaceHolder from '../../assets/images/placeholder.png'

function MovieCredits({ actor }) {
  const [opacity, setOpacity] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loadMoreLoading, setLoadMoreLoading] = useState(false)
  const [loadMore, setLoadMore] = useState(10)
  const [movieCredits, setMovieCredits] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      if (!actor) return
      actorRoutes.getCredits(actor.id)
        .then(res => {
          if (!res) return
          setMovieCredits(res.data.cast)
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
        .finally(() => setLoading(false))
    }
    if (actor.id) {
      fetchData()
    }
  }, [actor])

  useEffect(() => {
    setTimeout(() => {
      setOpacity('1')
    }, 800)
  }, [])

  const redirect = (credits) => {
    if (credits.media_type === 'tv') {
      window.location.assign(`/shows/${credits.id}/${credits.name || credits.original_name || credits.title || credits.original_title}`)
    } else {
      window.location.assign(`/movies/${credits.id}/${credits.name || credits.original_name || credits.title || credits.original_title}`)
    }
  }

  const handleLoadMore = () => {
    setLoadMoreLoading(true)
    setTimeout(() => {
      setLoadMore(loadMore + 10)
      setLoadMoreLoading(false)
    }, 800)
  }

  // sorting the movie/shows by release year
  movieCredits.sort((a, b) =>
    (a.release_date || a.first_air_date) > (b.release_date || b.first_air_date)
      ? -1
      : (b.release_date || b.first_air_date) > (a.release_date || a.first_air_date)
        ? 1
        : 0
  )

  const slicedCredits = movieCredits.slice(0, loadMore)
  return (
    <>
      {loading ? (
        <StyledCircularProgressDiv>
          <CircularProgress size={70} />
        </StyledCircularProgressDiv>
      ) : (
        <StyledCreditsCastPosters>
          <StyledCreditsGridDiv>
            {slicedCredits?.map(credits => (
              <div key={credits.id}>
                <StyledCreditsCastsMap>
                  <StyledCreditsCastsPosterImg
                    style={{ opacity: opacity }}
                    onClick={() => redirect(credits)}
                    src={credits?.poster_path ? `${base_url}${credits?.poster_path}` : PlaceHolder}
                    alt={credits?.original_name}
                  />
                  <StyledCreditsCastsName>{credits?.original_title || credits?.original_name}</StyledCreditsCastsName>
                  {credits.character && (
                    <StyledCreditsCastsCharacter><em style={{ color: '#e94343' }}>{credits?.character}</em></StyledCreditsCastsCharacter>
                  )}
                  {(credits?.release_date || credits?.first_air_date) && (
                    <StyledCreditsCastsCharacter>
                      {credits?.release_date?.split('-')[0] || credits?.first_air_date?.split('-')[0]}
                    </StyledCreditsCastsCharacter>
                  )}
                  <StyledCreditsCastsEpisodes style={{ visibility: `${credits?.episode_count ? '' : 'hidden'}` }}>{`${credits?.episode_count} ${credits?.episode_count > 1 ? 'Episodes' : 'Episode'}`}</StyledCreditsCastsEpisodes>
                </StyledCreditsCastsMap>
              </div>
            ))}
          </StyledCreditsGridDiv>
        </StyledCreditsCastPosters>
      )}
      {!loading && (movieCredits?.length > loadMore) && (
        <StyledMovieCreditsBtn>
          {loadMoreLoading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <CircularProgress size={35} />
            </div>
          ) : (
            <Button variant='outlined' onClick={() => handleLoadMore()}>Load More</Button>
          )}
        </StyledMovieCreditsBtn>
      )}
    </>
  )
}

export default MovieCredits