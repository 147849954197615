import React, { useState } from 'react'
import Row from '../../components/Row'
import movieRoutes from '../../client/movie-client'

const Discover = () => {
    document.title = 'Discover'
    const [currentPage, setCurrentPage] = useState(1)

    return (
        <Row
            fetchUrl={movieRoutes.getTrending(currentPage)}
            title={'Discover'}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
        />
    )
}

export default Discover