import axiosConfig from '../axios';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    sendUserInfo: info => {
        return axiosConfig.post('/api/users/user-info', {
            username: info.nickname,
            sub: info.sub,
            email: info.email,
            emailVerified: info.email_verified,
            updatedAt: info.updated_at
        })
    },
    getUser: (username) => {
        return axiosConfig.get(`/api/users/${username}`);
    },

    getAllUsers: () => {
        return axiosConfig.get('/api/users');
    },

    loggedIn: (username) => {
        return axiosConfig.get(`/api/users/user/${username}`);
    },

    //////// FAVORITES ////////
    getAllFavorites: () => {
        return axiosConfig.get(`/api/favorites`)
    },

    addMovieToFavorite: (username, MovieId, PosterPath, Title, Genres) => {
        return axiosConfig.post('/api/favorites/add-movie-to-favorites', {
            username: username,
            MovieId: MovieId,
            PosterPath: PosterPath,
            Title: Title,
            Genres: Genres
        })
    },

    addShowToFavorite: (username, ShowId, PosterPath, Title, Genres) => {
        return axiosConfig.post('/api/favorites/add-show-to-favorites', {
            username: username,
            ShowId: ShowId,
            PosterPath: PosterPath,
            Title: Title,
            Genres: Genres
        })
    },

    removeMovieFromFavorites: (username, MovieId) => {
        return axiosConfig.delete(`/api/favorites/remove-movie-from-favorites/${username}/${MovieId}`)
    },

    removeShowFromFavorites: (username, ShowId) => {
        return axiosConfig.delete(`/api/favorites/remove-show-from-favorites/${username}/${ShowId}`)
    },


    //////// WATCHLIST ////////
    getAllWatchList: () => {
        return axiosConfig.get(`/api/watchlist`)
    },

    addMovieToWatchList: (username, MovieId, PosterPath, Title, Genres) => {
        return axiosConfig.post('/api/watchlist/add-movie-to-watchlist', {
            username: username,
            MovieId: MovieId,
            PosterPath: PosterPath,
            Title: Title,
            Genres: Genres
        })
    },

    addShowToWatchList: (username, ShowId, PosterPath, Title, Genres) => {
        return axiosConfig.post('/api/watchlist/add-show-to-watchlist', {
            username: username,
            ShowId: ShowId,
            PosterPath: PosterPath,
            Title: Title,
            Genres: Genres
        })
    },

    removeMovieFromWatchList: (username, MovieId) => {
        return axiosConfig.delete(`/api/watchlist/remove-movie-from-watchlist/${username}/${MovieId}`)
    },

    removeShowFromWatchList: (username, ShowId) => {
        return axiosConfig.delete(`/api/watchlist/remove-show-from-watchlist/${username}/${ShowId}`)
    },

    updateMovieFromWatchList: (movieArrToUpdate) => {
        return axiosConfig.put('/api/watchlist/update-movie', movieArrToUpdate)
    },


    //////// HISTORY ////////
    getAllWatched: () => {
        return axiosConfig.get(`/api/history/watched`)
    },

    addMovieToWatched: (username, MovieId, PosterPath, Title, Genres) => {
        return axiosConfig.post('/api/history/add-movie-to-history', {
            username: username,
            MovieId: MovieId,
            PosterPath: PosterPath,
            Title: Title,
            Genres: Genres
        })
    },

    addShowToWatched: (username, MovieId, PosterPath, Title, Genres, NumberOfEpisodes, NumberOfSeasons) => {
        return axiosConfig.post('/api/history/add-show-to-history', {
            username: username,
            ShowId: MovieId,
            PosterPath: PosterPath,
            Title: Title,
            Genres: Genres,
            NumberOfEpisodes: NumberOfEpisodes,
            NumberOfSeasons: NumberOfSeasons
        })
    },

    removeMovieFromWatched: (username, MovieId) => {
        return axiosConfig.delete(`/api/history/remove-movie-from-history/${username}/${MovieId}`)
    },

    removeShowFromWatched: (username, MovieId) => {
        return axiosConfig.delete(`/api/history/remove-show-from-history/${username}/${MovieId}`)
    },

    updateShowFromWatched: (updateArr) => {
        return axiosConfig.put('/api/history/update-show', updateArr)
    },


    //////// EPISODES ////////
    getAllEpisodes: (username) => {
        return axiosConfig.get(`/api/watchedepisodes/${username}/episodes`)
    },

    addAllEpisodesToWatched: (username, ShowId, ShowTitle, SeasonId, Episodes, ShowPosterPath, SeasonPosterPath) => {
        Episodes = Episodes.map(episode => {
            delete episode.crew
            delete episode.guest_stars
            delete episode.overview
            delete episode.production_code
            delete episode.air_date
            delete episode.still_path
            delete episode.vote_average
            delete episode.vote_count
            return episode
        })

        return axiosConfig.post(`/api/watchedepisodes/allwatchedepisodes`, {
            username: username,
            show_id: +ShowId,
            show_title: ShowTitle,
            season_id: +SeasonId,
            show_poster_path: ShowPosterPath,
            season_poster_path: SeasonPosterPath,
            episodes: Episodes
        })
    },

    removeAllEpisodesFromWatched: (username, ShowId, ShowTitle, SeasonId) => {
        return axiosConfig.delete(`/api/watchedepisodes/${username}/show/${ShowId}/show_title/${ShowTitle}/season/${SeasonId}`)
    },

    addEpisodetoWatched: (username, ShowId, ShowTitle, SeasonId, Episode, EpisodeId, EpisodeTitle, Runtime, ShowPosterPath, SeasonPosterPath) => {
        return axiosConfig.post('/api/watchedepisodes', {
            username: username,
            show_id: +ShowId,
            show_title: ShowTitle,
            season_id: +SeasonId,
            episode_number: +Episode,
            id: +EpisodeId,
            name: EpisodeTitle,
            runtime: +Runtime,
            show_poster_path: ShowPosterPath,
            season_poster_path: SeasonPosterPath
        })
    },

    removeEpisodeFromWatched: (username, ShowId, ShowTitle, SeasonId, Episode, EpisodeId) => {
        return axiosConfig.delete(`/api/watchedepisodes/${username}/show/${ShowId}/show_title/${ShowTitle}/season/${SeasonId}/episode/${Episode}/${EpisodeId}`)
    },

    increaseShowRewatched: (username, ShowId, ShowTitle) => {
        return axiosConfig.post('/api/watchedepisodes/increase-rewatched', {
            username: username,
            show_id: +ShowId,
            show_title: ShowTitle
        })
    },

    decreaseShowRewatched: (username, ShowId, ShowTitle) => {
        return axiosConfig.post('/api/watchedepisodes/decrease-rewatched', {
            username: username,
            show_id: +ShowId,
            show_title: ShowTitle
        })
    },
};