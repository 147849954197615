import React, { useState, useEffect } from 'react'
import Banner from '../Banner'
import { useParams } from 'react-router-dom'
import { base_url } from '../../utils/helper'
import { CircularProgress, Grid, Link } from '@mui/material'
import {
  StyledCollectionMainDiv,
  StyledCollectionOverviews,
  StyledCollectionImageContainer,
  StyledCollectionImage,
  StyledCircularProgressDiv,
  StyledRowCard,
  StyledRowCardMedia
} from '../../utils/styled'
import movieRoutes from '../../client/movie-client'
import PlaceHolder from '../../assets/images/placeholder.png'

function Collections() {
  const [collection, setCollection] = useState([])
  const [loading, setLoading] = useState(false)
  const [opacity, setOpacity] = useState(0)
  const { CollectionId } = useParams()

  useEffect(() => {
    setOpacity('0')
    setTimeout(() => {
      setOpacity('1')
    }, 600)
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      movieRoutes.getCollections(CollectionId)
        .then(res => {
          setCollection(res.data)
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
        .finally(() => setLoading(false))
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  }

  collection?.parts?.sort((a, b) =>
    (a.release_date) > (b.release_date)
      ? 1
      : (b.release_date) > (a.release_date)
        ? -1
        : 0
  )

  return (
    <div>
      {loading ? (
        <StyledCircularProgressDiv>
          <CircularProgress size={70} />
        </StyledCircularProgressDiv>
      ) : (
        <>
          <Banner link={collection?.backdrop_path} />
          <StyledCollectionMainDiv>
            <StyledCollectionImageContainer>
              <StyledCollectionImage
                src={`${base_url}${collection?.poster_path}`}
              />
            </StyledCollectionImageContainer>
            <div style={{ zIndex: '1 !important' }}>
              <h3>{collection?.name}</h3>
            </div>
            <div>{collection?.overview}</div>
          </StyledCollectionMainDiv>
          <div
            style={{
              display: 'flex',
              marginTop: '3.5rem',
              justifyContent: 'center',
              alignItems: ' center',
              flexWrap: 'wrap'
            }}
          >
            {collection?.parts?.map((collection, id) => (
              <Grid item key={id} sx={{ opacity: opacity, transition: 'opacity 1s ease-in-out' }}>
                <StyledRowCard
                  sx={{
                    maxWidth: 312,
                    minHeight: 650,
                    transition: 'box-shadow .7s ease-in-out',
                    '&: hover': {
                      boxShadow: '0 3px 8px 0 rgba(255, 127, 80, 0.8), 0 1px 30px 0 rgba(255, 127, 80, 0.9)'
                    },
                    m: 2
                  }}
                >
                  <Link href={`/movies/${collection?.id}/${collection?.original_title || collection?.title}`}>
                    <StyledRowCardMedia
                      sx={[{ height: 468, width: 312, backgroundSize: 'contain' }]}
                      image={
                        collection.poster_path ? `${base_url}${collection.poster_path}` : PlaceHolder
                      }
                    />
                  </Link>
                  <StyledCollectionOverviews style={{ padding: '1.5rem' }}>
                    <p>{collection?.original_title || collection?.title}</p>
                    <div>{new Date(collection?.release_date).toLocaleString('en-US', options)}</div>
                    <div style={{ visibility: `${collection?.vote_average > 0 ? '' : 'hidden'}` }}>Vote Average: {collection?.vote_average.toFixed(2)} / 10</div>
                  </StyledCollectionOverviews>
                </StyledRowCard>
              </Grid>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default Collections
