import React, { useState } from 'react'
import Row from '../../components/Row'
import showRoutes from '../../client/show-client'

const MarvelShows = () => {
  document.title = 'Marvel'
  const [currentPage, setCurrentPage] = useState(1)

  return (
    <div>
      <Row
        fetchUrl={showRoutes.getMarvelShows(currentPage)}
        title={'Marvel Shows'}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        marvel={true}
      />
    </div>
  )
}

export default MarvelShows