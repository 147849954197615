import axiosConfig from '../axios'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTrending: (page) => {
    return axiosConfig.get(`/api/v1.0/movies/trending-all`, {
      params: {
        page: page
      }
    })
  },
  getTrendingMovies: (page) => {
    return axiosConfig.get(`/api/v1.0/movies/trending`, {
      params: {
        page: page
      }
    })
  },
  getNetflixOriginal: (page) => {
    return axiosConfig.get('/api/v1.0/movies/netflix-originals', {
      params: {
        page: page
      }
    })
  },
  getPopular: page => {
    return axiosConfig.get(`/api/v1.0/movies/popular`, {
      params: {
        page: page
      }
    })
  },
  getTopRated: () => {
    return axiosConfig.get('/api/v1.0/movies/top-rated')
  },
  getNowPlaying: () => {
    return axiosConfig.get('/api/v1.0/movies/now-playing')
  },
  getSearchMulti: query => {
    return axiosConfig.get(`/api/v1.0/movies/${query}/search-multi`)
  },
  getSearchMovie: query => {
    return axiosConfig.get(`/api/v1.0/movies/${query}/search`)
  },
  getSearchCollection: query => {
    return axiosConfig.get(`/api/v1.0/movies/${query}/search-collection`)
  },
  getDiscoverByReleaseYear: query => {
    return axiosConfig.get(`/api/v1.0/movies/${query}/discover-by-release-year`)
  }
  ,
  getMovie: MovieId => {
    return axiosConfig.get(`/api/v1.0/movies/${MovieId}/movie`)
  },
  getMovieVideos: MovieId => {
    return axiosConfig.get(`/api/v1.0/movies/${MovieId}/videos`)
  },
  getMovieExternalId: MovieId => {
    return axiosConfig.get(`/api/v1.0/movies/${MovieId}/externalIds`)
  },
  getMovieRecommendations: MovieId => {
    return axiosConfig.get(`/api/v1.0/movies/${MovieId}/recommendations`)
  },
  getMovieCredits: MovieId => {
    return axiosConfig.get(`/api/v1.0/movies/${MovieId}/credits`)
  },
  getCollections: CollectionId => {
    return axiosConfig.get(`/api/v1.0/movies/${CollectionId}/collections`)
  },
  getUpcoming: () => {
    return axiosConfig.get('/api/v1.0/movies/upcoming')
  },
  getWatchProviders: MovieId => {
    return axiosConfig.get(`/api/v1.0/movies/${MovieId}/watch-providers`)
  },
  getMarvelMovies: page => {
    return axiosConfig.get('/api/v1.0/movies/marvel-movies', {
      params: {
        page: page
      }
    })
  }
}