import React from 'react'
// import React, { useState, useEffect } from 'react'
// import { Button, CircularProgress } from '@mui/material'
// import { UpperProfile, GeneralSettings, PersonalInformationsSettings, NotLoggedIn, Social } from '../../components'
// import { Save as SaveIcon } from '@mui/icons-material'
// import styled from 'styled-components'
// import API from '../../client/API-client'
// import { StyledCircularProgressDiv } from '../../utils/styled'
// import { createBinder } from '../../hooks/createBinder'
// import { useSnackbar } from 'notistack'

// const StyledMainDiv = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: flex-start;
//   flex-wrap: wrap;
//   gap: 1rem;
// `
// const StyledDiv = styled.div`
//   transition: opacity ease-in-out 0.8s;
// `

const Settings = ({ loggedInUser, isLoading, isDarkMode, setIsDarkMode }) => {
  // const [user, setUser] = useState({})
  // const [opacity, setOpacity] = useState(0)
  // const [username, setUsername] = useState()
  // const [loading, setLoading] = useState(false)
  // const [loadingBtn] = useState(false)

  // const bindValue = createBinder(user, setUser)
  // const { enqueueSnackbar } = useSnackbar()

  // useEffect(() => {
  //   setTimeout(() => {
  //     setOpacity('1')
  //   }, 500)
  // }, [])

  // useEffect(() => {
  //   setLoading(true)
  //   API.loggedIn()
  //     .then((result) => {
  //       setUser(result.data)
  //       setUsername(result.data.username)
  //     })
  //     .finally(() => setLoading(false))
  // }, [])

  // const handleUpdateUser = () => {
  //   setLoadingBtn(true)
  //   let usernameChanged = false
  //   if (username !== user.username) {
  //     usernameChanged = true
  //     setUsername(user.username)
  //   }

  //   const userData = {
  //     name: user.name,
  //     username: user.username,
  //     email: user.email,
  //     is_dark_mode: user.isDarkMode,
  //     is_profile_public: user.isProfilePublic,
  //     keep_logged_in_for_30_days: user.keepLoggedInFor30Days,
  //     dob: user.dob,
  //     usernameChanged: usernameChanged,
  //   }
  //   API.updateUser(userData)
  //     .then((res) => {
  //       if (res.data === 'ok') {
  //         setTimeout(() => {
  //           enqueueSnackbar('Successfully updated your settings!', {
  //             variant: 'success',
  //           })
  //         }, 1000)
  //       } else {
  //         enqueueSnackbar(res.data, {
  //           variant: 'error',
  //         })
  //       }
  //     })
  //     .finally(() => {
  //       setTimeout(() => {
  //         setLoadingBtn(false)
  //       }, 1000)
  //     })
  // }

  // make sure page is loaded on top
  window.onbeforeunload = () => {
    window.scrollTo(0, 0)
  }

  // const style = {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   marginBottom: '1.5rem',
  // }

  return (
    <>
      {/* {isLoading || opacity < 0 ? (
        <StyledCircularProgressDiv>
          <CircularProgress size={70} />
        </StyledCircularProgressDiv>
      ) : !loggedInUser?.username && !isLoading ? (
        <NotLoggedIn />
      ) : (
        <StyledDiv style={{ opacity: opacity }}>
          <UpperProfile user={loggedInUser} />
          {loadingBtn ? (
            <div style={style}>
              <CircularProgress size={35} />
            </div>
          ) : (
            <div style={style}>
              <Button
                fullWidth
                color='warning'
                variant='outlined'
                sx={{ maxWidth: 345, color: 'coral' }}
              // onClick={handleUpdateUser}
              >
                Save <SaveIcon sx={{ pl: 0.4 }} />
              </Button>
            </div>
          )}
          <StyledMainDiv>
            <GeneralSettings user={loggedInUser} setUser={setUser} bindValue={bindValue} />
            <PersonalInformationsSettings user={loggedInUser} setUser={setUser} bindValue={bindValue} />
            <Social />
          </StyledMainDiv>
        </StyledDiv>
      )} */}
      <h1 style={{ textAlign: 'center' }}>Settings page is disabled to fix some errors, will be back shortly!</h1>
    </>
  )
}

export default Settings
