import axiosConfig from '../axios'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getTrending: (page) => {
    return axiosConfig.get('/api/v1.0/shows/trending', {
      params: {
        page: page
      }
    })
  },
  getPopular: () => {
    return axiosConfig.get('/api/v1.0/shows/popular')
  },
  getShows: () => {
    return axiosConfig.get('/api/v1.0/shows')
  },
  getSearchShow: (ShowId) => {
    return axiosConfig.get(`/api/v1.0/shows/${ShowId}/search`)
  },
  getShow: (ShowId) => {
    return axiosConfig.get(`/api/v1.0/shows/${ShowId}/show`)
  },
  getShowVideos: (ShowId) => {
    return axiosConfig.get(`/api/v1.0/shows/${ShowId}/videos`)
  },
  getShowExternalId: (ShowId) => {
    return axiosConfig.get(`/api/v1.0/shows/${ShowId}/externalIds`)
  },
  getShowRecommendations: (ShowId) => {
    return axiosConfig.get(`/api/v1.0/shows/${ShowId}/recommendations`)
  },
  getShowCredits: (ShowId) => {
    return axiosConfig.get(`/api/v1.0/shows/${ShowId}/credits`)
  },
  getEpisodes: (ShowId, SeasonNo) => {
    return axiosConfig.get(`/api/v1.0/shows/${ShowId}/season/${SeasonNo}`)
  },
  getWatchProviders: ShowId => {
    return axiosConfig.get(`/api/v1.0/shows/${ShowId}/watch-providers`)
  },
  getMarvelShows: () => {
    return axiosConfig.get('/api/v1.0/shows/marvel-shows')
  }
}