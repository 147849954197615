import React from "react"
import HeartIcon from '../Icons/heart'
import HistoryIcon from '../Icons/history'
import WatchListIcon from '../Icons/watchList'
import styled from 'styled-components'

const StyledMainDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledButtons = styled.div`
  padding: 0.5rem;
  transform: scale(1.2)
`

function Buttons({ user, movie }) {
  return (
    <StyledMainDiv>
      <StyledButtons>
        <HistoryIcon movie={movie} user={user} />
      </StyledButtons>
      <StyledButtons>
        <HeartIcon movie={movie} user={user} />
      </StyledButtons>
      <StyledButtons>
        <WatchListIcon movie={movie} user={user} />
      </StyledButtons>
    </StyledMainDiv>
  )
}

export default Buttons
