import React, { useState, useEffect } from 'react'
import movieClient from '../../client/movie-client'
import { base_url } from '../../utils/helper'
import styled from 'styled-components'
import { Tooltip } from '@mui/material'
import showClient from '../../client/show-client'

const StyledImg = styled.img`
    object-fit: contain;
    width: 100%;
    max-height: 50px;
    padding: .2rem;
    font-size: 0.5rem;
    &: hover {
      cursor: pointer;
    }
`
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

function WatchProviders({ movie, show }) {
  const [movieProviders, setMovieProviders] = useState([])

  useEffect(() => {
    if (movie?.id) {
      const fetchData = async () => {
        movieClient.getWatchProviders(movie?.id)
          .then(res => {
            if (!res) return
            setMovieProviders(res.data.results)
          })
      }
      fetchData()
    } else if (show?.id) {
      const fetchData = async () => {
        showClient.getWatchProviders(show?.id)
          .then(res => {
            if (!res) return
            setMovieProviders(res.data.results)
          })
      }
      fetchData()
    }
  }, [movie?.id, show?.id])

  const redirect = (provider) => {
    const name = movie?.title || movie?.original_title || show?.name || show?.original_name
    window.open(`https://www.google.com/search?q=${name}+${provider.provider_name}`, '_blank')
  }

  return (
    <>
      <div>
        {movieProviders?.US?.flatrate && (
          <div>
            <div style={{ margin: '1rem 0rem', textAlign: 'center' }}>Available To Watch On;</div>
            <StyledDiv>
              {movieProviders?.US?.flatrate?.map((provider, id) => (
                <div key={id}>
                  <Tooltip title={provider?.provider_name}>
                    <StyledImg
                      key={provider?.id}
                      onClick={() => redirect(provider)}
                      src={`${base_url}${provider?.logo_path}`}
                      alt={provider?.provider_name}
                    />
                  </Tooltip>
                </div>
              ))}
            </StyledDiv>
          </div>
        )}
      </div>
    </>
  )
}

export default WatchProviders
