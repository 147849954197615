import { Card, CardActions, CardMedia, Container, FormControl, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import styled from 'styled-components'

// Main App Styles //
const StyledMainAppDiv = styled.div`
  padding: 5rem 10rem;
  min-height: 100vh;
  @media (max-width: 882px) {
    padding: 3rem
  }
`
const StyledDivForMoviesNShowPages = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const StyledCircularProgressDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
`
const StyledFlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledMarginBottom = styled.div`
  margin-bottom: 2rem;
`
const StyledSearchIcon = styled(SearchIcon)`
  &: hover {
    cursor: pointer;
  }
`
const StyledDFlexCenterCenterWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
// ---------------------------------------------------------------- //

// Banner Styles //
const StyledBannerDiv = styled.div`
  margin: -5rem -10rem -20rem -10rem;
  @media (max-width: 882px) {
    margin: -3rem -3rem -35rem -3rem;
  }
`
const StyledFadeBottom = styled.div`
    position: relative;
    top: 31.6rem;
    height: 16.1rem;
    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.61), #000);
`
// ---------------------------------------------------------------- //

// Row Styles //
const StyledRowCard = styled(Card)`
  maxWidth: 312px;
  minHeight: 550px;
  @media (max-width: 550px) {
    width: 312px;
  }
`
const StyledRowCardMedia = styled(CardMedia)`
  height: 469px;
  width: 312px;
  transition: opacity ease-in-out 0.8s;
  transition: border-color ease-in-out 1s;
  border-color: transparent;
  &: hover {
    cursor: pointer;
    // border: 2px solid coral;
  }
`
const StyledRowCardPersonMedia = styled(CardMedia)`
  transition: opacity ease-in-out 0.8s;
  height: 469px;
  width: 312px;
  &: hover {
    cursor: pointer;
  }
`
const StyledRowCardContentContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const StyledRowCardActions = styled(CardActions)`
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledRowCardContentIconsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledRowTypography = styled(Typography)`
  text-align: center;
  padding: 0.8rem;
`
// ---------------------------------------------------------------- //

// Login - Signup //

const StyledLogInContainer = styled(Container)`
  padding: 1.8rem;
  border-radius: 12%;
`
// ---------------------------------------------------------------- //

// Credits //

const StyledCreditsGridDiv = styled.div`
  transition: all 1s;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
const StyledCreditsCastPosters = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  // margin: 1rem 1rem 0rem 0rem;
  transition: transform 0.3s ease-in-out;
  transition: opacity 1s ease-in-out;
  margin-top: 2rem;
`
const StyledCreditsCastsMap = styled.div`
  text-align: center;
  margin-top: 1rem;
  transition: transform 0.3s ease-in-out;
  &: hover {
    cursor: pointer;
    transform: scale(1.10);
    white-space: normal;
  }
`
const StyledCreditsCastsName = styled.div`
  width: 9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.9rem;
  text-align: center;
  padding: 0.3rem 1rem 0rem 1rem;
  transition: transform 0.3s ease-in-out;
  &: hover {
    overflow: visible;
  }
`
const StyledCreditsCastsEpisodes = styled.div`
  width: 9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8rem;
  text-align: center;
`
const StyledCreditsCastsCharacter = styled.div`
  width: 9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: italic;
  font-weight: 300;
  padding: 0.3rem;
  font-size: 0.8rem;
  color: #dbd6d6;
  transition: transform 0.6s ease-in-out;
`
const StyledCreditsCastsPosterImg = styled.img`
  object-fit: contain;
  width: 8rem;
  border-radius: 5%;
  transition: opacity 1s ease-in-out;
`
const StyledCreditsSeeBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`
const StyledMovieCreditsBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
`
// ---------------------------------------------------------------- //

// Collections //

const StyledCollectionMainDiv = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const StyledCollectionContainer = styled(Container)`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
const StyledCollectionImageContainer = styled.div`
  min-height: 468px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledCollectionImage = styled.img`
  height: 468px;
  width: 312px;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  z-index: 1 !important;
`
const StyledCollectionMap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`
const StyledCollectionPoster = styled.img`
  margin: 2rem;
  width: 12rem;
  border-radius: 5%;
  object-fit: contain;
  transition: transform 0.6s ease-in-out, opacity 1s ease-in-out, box-shadow .7s ease-in-out;
  &: hover {
    cursor: pointer;
    box-shadow: 0 3px 8px 0 rgba(255, 127, 80, 0.8), 0 1px 30px 0 rgba(255, 127, 80, 0.9);
  }
`
const StyledCollectionOverviews = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
`

// ---------------------------------------------------------------- //

// Recommendations //

const StyledRecommendationsPosters = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
const StyledRecommendationsMap = styled.div`
  text-align: center;
  margin-top: 1rem;
  transition: transform 450ms;
  &: hover {
    cursor: pointer;
    transform: scale(1.10);
    white-space: normal;
  }
`
const StyledRecommendationsName = styled.div`
    width: 9em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 0.9rem;
    transition: white-space 0.6s ease-in-out;
`
const StyledRecommendationsPoster = styled.img`
    object-fit: contain;
    width: 8rem;
    border-radius: 5%;
    transition: transform 0.6s ease-in-out;
    transition: opacity 1s ease-in-out;
    margin: 0.5rem;
`

// ---------------------------------------------------------------- //

// Search Modal //

const StyledContainerForSearchModal = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 8rem;
  margin-top: 5rem;
  background-color: rgb(0, 0, 0);
  border-radius: 25px;
`
const StyledAdvancedSearchDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledAdvancedSearchFormControl = styled(FormControl)`
  width: 75%;
`
// ---------------------------------------------------------------- //

// Seasons //

const StyledSeasonsPosters = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  margin: 0 -2rem;
`
const StyledSeasonsMap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  transition: transform 450ms;
  &: hover{
    cursor: pointer;
    transform: scale(1.10);
    white-space: normal;
  }
`
const StyledSeasonsName = styled.div`
  width: 8em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const StyledSeasonsPoster = styled.img`
  object-fit: contain;
  border-radius: 2rem;
  width: 10rem;
  margin: 0px 5px;
  transition: transform 450ms;
`
const StyledSeasonsEpisodes = styled.div`
  margin-bottom: 2rem;
`
const StyledSeasonsButtonsDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
`
// ---------------------------------------------------------------- //

// Episodes //

const StyledEpisodesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
`
const StyledEpisodesGrid = styled.div`
  margin: 2rem 0rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  transition: all 1s;
  @media (max-width: 1042px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

// ---------------------------------------------------------------- //



// ---------------------------------------------------------------- //

export {
  StyledSearchIcon,
  StyledMarginBottom,
  StyledDivForMoviesNShowPages,
  StyledCircularProgressDiv,
  StyledRowCard,
  StyledBannerDiv,
  StyledFadeBottom,
  StyledMainAppDiv,
  StyledDFlexCenterCenterWrap,
  //
  StyledRowCardMedia,
  StyledRowCardPersonMedia,
  StyledRowTypography,
  StyledRowCardActions,
  //
  StyledLogInContainer,
  //
  StyledRowCardContentIconsDiv,
  StyledRowCardContentContainerDiv,
  //
  StyledCreditsGridDiv,
  StyledCreditsCastPosters,
  StyledCreditsCastsMap,
  StyledCreditsCastsName,
  StyledCreditsCastsEpisodes,
  StyledCreditsCastsCharacter,
  StyledCreditsCastsPosterImg,
  StyledCreditsSeeBtn,
  //
  StyledMovieCreditsBtn,
  //
  StyledCollectionMainDiv,
  StyledCollectionContainer,
  StyledCollectionMap,
  StyledCollectionPoster,
  StyledCollectionOverviews,
  StyledCollectionImageContainer,
  StyledCollectionImage,
  //
  StyledContainerForSearchModal,
  StyledAdvancedSearchDiv,
  StyledAdvancedSearchFormControl,
  //
  StyledRecommendationsPosters,
  StyledRecommendationsMap,
  StyledRecommendationsName,
  StyledRecommendationsPoster,
  //
  StyledSeasonsPosters,
  StyledSeasonsMap,
  StyledSeasonsName,
  StyledSeasonsPoster,
  StyledSeasonsEpisodes,
  StyledSeasonsButtonsDiv,
  //
  StyledEpisodesContainer,
  StyledEpisodesGrid,
  //
  StyledFlexDiv
}