/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import useSearch from '../../hooks/useSearch'
import SearchIcon from '@mui/icons-material/Search'
import { StyledAdvancedSearchDiv, StyledAdvancedSearchFormControl, StyledContainerForSearchModal } from '../../utils/styled'
import { Button, Modal, InputLabel, MenuItem, Select, FormControl, Typography, TextField, InputAdornment, Autocomplete } from '@mui/material'
// import genres from '../../utils/genres'

function SearchModal({ show, handleClose }) {
  // const [input, setInput] = useState('')
  // const [genre, setGenre] = useState('')
  const [search, setSearch] = useState('')
  const [required, setRequired] = useState(false)
  // const [advancedSearch, setAdvancedSearch] = useState(false)
  const { result, loading } = useSearch(search)

  // const handleChange = event => {
  //   const timer = setTimeout(() => {
  //     setInput(event.target.value)
  //   }, 500)

  //   return () => clearTimeout(timer)
  // }

  // const handleGenreChange = (event) => {
  //   setGenre(event.target.value)
  // }

  const handleSearchEnter = (e) => {
    if (!search) {
      setRequired(true)
      return
    }
    if (e.charCode === 13) {
      // if (advancedSearch) {
      //   window.location.assign(`/search?input=${input}&query=${search}`)
      //   return
      // }
      window.location.assign(`/search?query=${search}`)
    }
  }

  const handleSubmit = () => {
    if (!search) {
      setRequired(true)
      return
    }
    // if (advancedSearch) {
    //   window.location.assign(`/search?input=${input}&query=${search}`)
    //   return
    // }
    window.location.assign(`/search?query=${search}`)
  }



  return (
    <Modal open={show} onClose={handleClose}>
      <StyledContainerForSearchModal maxWidth='md'>
        <FormControl required sx={{ m: 2 }}>
          <Typography variant='h6' sx={{ mb: 2 }}>You can search for Movies, Shows and Actors!</Typography>
          {/* <Typography variant='h6' sx={{ mb: 2 }}>You can search for Movies, Collections, Shows and Actors!</Typography> */}
          <Autocomplete
            loading={loading}
            popupIcon={''}
            noOptionsText={'Couldn\'t find anything, sorry!'}
            options={result?.map(r => r.title || r.original_title || r.name || r.original_name)}
            renderInput={(params, idx) => (
              <TextField
                {...params}
                key={idx}
                error={required}
                autoFocus={true}
                helperText={required && 'Input cannot be empty'}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                onChange={e => {
                  setRequired(false)
                  setSearch(e.target.value)
                }}
                onKeyPress={handleSearchEnter}
              />
            )}
          />
        </FormControl>
        <StyledAdvancedSearchDiv>
          {/* <Button style={{ marginBottom: '1rem' }} onClick={() => setAdvancedSearch(!advancedSearch)}>Advanced Search</Button> */}
          {/* {advancedSearch && ( */}
          <>
            {/* <StyledAdvancedSearchFormControl sx={{ m: 1 }}>
                <InputLabel>What are you searching for?</InputLabel>
                <Select value={input} label='Movie or Show' onChange={handleChange}>
                  <MenuItem value={'movie'}>Movie</MenuItem>
                  <MenuItem value={'collection'}>Collection</MenuItem>
                  <MenuItem value={'show'}>TV Show</MenuItem>
                  <MenuItem value={'actor'}>Actors</MenuItem>
                  <MenuItem value={'any'}>All</MenuItem>
                </Select>
              </StyledAdvancedSearchFormControl> */}
            {/* To be implemented later,  */}
            {/* <StyledAdvancedSearchFormControl sx={{ m: 1 }}>
                <InputLabel>Genres</InputLabel>
                <Select value={genre} label='Genre' onChange={handleGenreChange}>
                  {genres.map((genre, id) => (
                    <MenuItem value={genre.id} key={id}>{genre.name}</MenuItem>
                  ))}
                </Select>
              </StyledAdvancedSearchFormControl> */}
          </>
          {/* )} */}
        </StyledAdvancedSearchDiv>
        <div>
          <Button variant='outlined' onClick={handleSubmit}>Search</Button>
        </div>
      </StyledContainerForSearchModal>
    </Modal>
  )
}

export default SearchModal
