import axios from '../axios'
import { useState, useEffect } from "react"

const useSearch = (query) => {
  const [result, setResult] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setResult([])
  }, [query])

  useEffect(() => {
    if (!query) return
    setLoading(true)
    const timer = setTimeout(() => {
      axios.get(`/api/v1.0/movies/${query}/search-multi`)
        .then(res => {
          if (!res) return
          setResult(res.data)
          // setResult(prevResults => {
          //   return [...prevResults, res.data]
          // })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }, 500)
    return () => clearTimeout(timer)
  }, [query])
  return { result, loading }
}

export default useSearch