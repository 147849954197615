import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Row from '../Row'
import movieRoutes from '../../client/movie-client'
import showRoutes from '../../client/show-client'
import actorRoutes from '../../client/actor-client'

function SearchResults() {
  const search = useLocation().search
  const searchedQuery = new URLSearchParams(search).get('query')
  const input = new URLSearchParams(search).get('input')
  // const genre = new URLSearchParams(search).get('genre') // to be implemented later
  const [request, setRequest] = useState()

  useEffect(() => {
    if (!input) {
      setRequest(movieRoutes.getSearchMulti(searchedQuery))
    } else {
      switch (input) {
        case 'movie':
          setRequest(movieRoutes.getSearchMovie(searchedQuery))
          break
        case 'collection':
          setRequest(movieRoutes.getSearchCollection(searchedQuery))
          break
        case 'show':
          setRequest(showRoutes.getSearchShow(searchedQuery))
          break
        case 'actor':
          setRequest(actorRoutes.getSearchActor(searchedQuery))
          break
        case 'all':
          setRequest(movieRoutes.getSearchMulti(searchedQuery))
          break
        default:
          setRequest(movieRoutes.getSearchMulti(searchedQuery))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input])

  useEffect(() => {
    document.title = `Search Results - ${searchedQuery}`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div>
      <Row fetchUrl={request} />
    </div>
  )
}

export default SearchResults
