import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { base_url } from '../../utils/helper'
import PlaceHolder from '../../assets/images/placeholder.png'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Card, CardContent, Typography, Divider, Link } from '@mui/material'
import Info from './info'

const StyledArrowBackIosIcon = styled(ArrowBackIosIcon)`
  &: hover {
    cursor: pointer
  }
`
const StyledArrowForwardIosIcon = styled(ArrowForwardIosIcon)`
  &: hover {
    cursor: pointer
  }
`
const StyledImg = styled.img`
  min-height: 480px;
  max-width: 300px;
  margin-bottom: 1rem;
  &: hover {
    cursor: pointer;
  }
`
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`
const StyledDivColumn = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
const StyledOverviewDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
`

const HomeCarousels = ({ data, title, upcoming, movie, show }) => {
  const [index, setIndex] = useState(0)
  const [opacity, setOpacity] = useState(1)

  const handleArrow = () => {
    setOpacity(0)
    setTimeout(() => {
      setOpacity(1)
    }, 300)
  }

  useEffect(() => {
    if (!data) return
    data.map(d => (
      d.href = d.media_type === 'tv'
        ? `/shows/${d.id}/${d?.title || d?.original_title || d?.name || d?.original_name}`
        : `/movies/${d.id}/${d?.title || d?.original_title || d?.name || d?.original_name}`
    ))
  }, [data])

  return (
    <div>
      {data && (
        <Card
          sx={{
            minWidth: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'box-shadow .7s ease-in-out',
            '&: hover': {
              boxShadow: '0 3px 8px 0 rgba(255, 127, 80, 0.8), 0 1px 30px 0 rgba(255, 127, 80, 0.9)'
            }
          }}
        >
          <CardContent>
            <StyledDivColumn>
              <Typography variant='h5' sx={{ textAlign: 'center', mb: 2 }}>{title}</Typography>
              <div style={{ visibility: upcoming ? 'hidden' : '', marginBottom: '1rem' }} >#{index + 1} of Top {data?.length} {movie ? 'Movies' : 'Shows'}</div>
            </StyledDivColumn>
            <StyledDiv>
              <StyledArrowBackIosIcon style={{ visibility: index === 0 && 'hidden' }} onClick={() => { setIndex(index - 1); handleArrow() }} />
              <StyledDivColumn>
                <Link href={data?.[index].href}>
                  <StyledImg
                    style={{ opacity: opacity, transition: opacity > 0 && 'opacity .7s ease-in-out' }}
                    src={data?.[index]?.poster_path ? `${base_url}/${data?.[index]?.poster_path}` : PlaceHolder}
                    alt={data ? data?.[index]?.title : 'ANNEN'}
                  />
                </Link>
                <StyledOverviewDiv>
                  {data?.[index]?.title || data?.[index]?.original_title || data?.[index]?.name || data?.[index]?.original_name}
                </StyledOverviewDiv>
              </StyledDivColumn>
              <StyledArrowForwardIosIcon style={{ visibility: index === data.length - 1 && 'hidden' }} onClick={() => { setIndex(index + 1); handleArrow() }} />
            </StyledDiv>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <StyledDiv><Info data={data} upcoming={upcoming} movie={movie} show={show} index={index} /></StyledDiv>
          </CardContent>
        </Card>
      )}
    </div>
  )
}

export default HomeCarousels