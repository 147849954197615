import React, { useEffect, useState } from 'react'
import { base_url } from '../../utils/helper'
import { Button, CircularProgress } from '@mui/material'
import {
    StyledMovieCreditsBtn,
    StyledCreditsGridDiv,
    StyledRecommendationsPosters,
    StyledRecommendationsMap,
    StyledRecommendationsName,
    StyledRecommendationsPoster,
    StyledFlexDiv
} from '../../utils/styled'
import showRoutes from '../../client/show-client'
import movieRoutes from '../../client/movie-client'
import PlaceHolder from '../../assets/images/placeholder.png'

function Recommendations({ movie, show }) {
    const [opacity, setOpacity] = useState(0)
    const [loadMore, setLoadMore] = useState(10)
    const [loading, setLoading] = useState(false)
    const [showRecommendations, setShowRecommendations] = useState([])
    const [movieRecommendations, setMovieRecommendations] = useState([])

    useEffect(() => {
        if (movie?.id) {
            const fetchData = async () => {
                movieRoutes.getMovieRecommendations(movie.id)
                    .then(res => setMovieRecommendations(res.data.results))
            }
            fetchData()
        } else if (show?.id) {
            const fetchData = async () => {
                showRoutes.getShowRecommendations(show.id)
                    .then(res => setShowRecommendations(res.data.results))
            }
            fetchData()
        }
    }, [movie?.id, show?.id])

    const redirect = (recommendations) => {
        if (recommendations.media_type === 'movie') {
            window.location.assign(`/movies/${recommendations.id}/${recommendations?.title || recommendations?.original_title || recommendations?.name || recommendations?.name_title}`)
        } else {
            window.location.assign(`/shows/${recommendations.id}/${recommendations?.title || recommendations?.original_title || recommendations?.name || recommendations?.name_title}`)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setOpacity('1')
        }, 800)
    }, [])

    const handleLoadMore = () => {
        setLoading(true)
        setTimeout(() => {
            setLoadMore(loadMore + 10)
            setLoading(false)
        }, 800)
    }

    const slicedMovie = movieRecommendations.slice(0, loadMore)
    const slicedShow = showRecommendations.slice(0, loadMore)

    return (
        <div>
            {movie?.id ? (
                <div>
                    {movieRecommendations.length > 0 && (
                        <div>
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: '2rem',
                                    fontSize: '1.2rem',
                                }}
                            >
                                Since you are looking at {movie?.title || movie?.original_title}, you might like these movies;
                            </div>
                            <StyledRecommendationsPosters>
                                <StyledCreditsGridDiv>
                                    {slicedMovie?.map(
                                        (recommendations) =>
                                            <StyledRecommendationsMap key={recommendations.id}>
                                                <StyledRecommendationsPoster
                                                    style={{ opacity: opacity }}
                                                    key={recommendations.id}
                                                    onClick={() => redirect(recommendations)}
                                                    src={recommendations?.poster_path ? `${base_url}${recommendations?.poster_path}` : PlaceHolder}
                                                    alt={recommendations?.original_name}
                                                />
                                                <StyledRecommendationsName>{recommendations?.title || recommendations?.original_title || recommendations?.name || recommendations?.name_title}</StyledRecommendationsName>
                                            </StyledRecommendationsMap>
                                    )}
                                </StyledCreditsGridDiv>
                            </StyledRecommendationsPosters>
                            {movieRecommendations?.length > loadMore && (
                                <StyledMovieCreditsBtn>
                                    {!loading ?
                                        <Button variant='outlined' onClick={() => handleLoadMore()}>Load More</Button>
                                        :
                                        <StyledFlexDiv>
                                            <CircularProgress size={35} />
                                        </StyledFlexDiv>
                                    }
                                </StyledMovieCreditsBtn>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    {showRecommendations.length > 0 && (
                        <div>
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginTop: '2rem',
                                    fontSize: '1.2rem',
                                }}
                            >
                                Since you are looking at {show?.name}, you might like these shows;
                            </div>
                            <StyledRecommendationsPosters>
                                <StyledCreditsGridDiv>
                                    {slicedShow?.map(
                                        (recommendations) =>
                                            recommendations?.poster_path && (
                                                <StyledRecommendationsMap key={recommendations.id}>
                                                    <StyledRecommendationsPoster
                                                        style={{ opacity: opacity }}
                                                        key={recommendations.id}
                                                        onClick={() => redirect(recommendations)}
                                                        src={`${base_url}${recommendations?.poster_path}`}
                                                        alt={recommendations?.original_name}
                                                        className='recommendations-poster'
                                                    />
                                                    <StyledRecommendationsName>{recommendations?.title || recommendations?.original_title || recommendations?.name || recommendations?.name_title}</StyledRecommendationsName>
                                                </StyledRecommendationsMap>
                                            )
                                    )}
                                </StyledCreditsGridDiv>
                            </StyledRecommendationsPosters>
                            {showRecommendations?.length > loadMore && (
                                <StyledMovieCreditsBtn>
                                    {!loading ?
                                        <Button variant='outlined' onClick={() => handleLoadMore()}>Load More</Button>
                                        :
                                        <StyledFlexDiv>
                                            <CircularProgress size={35} />
                                        </StyledFlexDiv>
                                    }
                                </StyledMovieCreditsBtn>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Recommendations
