import React, { useEffect, useState } from 'react'
import { Divider, Typography } from '@mui/material'

const About = () => {
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setOpacity('1')
    }, 800)
  }, [])

  return (
    <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', gap: '2rem', opacity: opacity, transition: 'opacity 1s ease-in-out' }}>
      <Typography variant='h6'>
        Welcome to Movieception!
      </Typography>
      <Divider />
      <Typography variant='h6'>
        At Movieception, we are passionate about movies and TV shows. Our goal is to provide you with a seamless platform to discover, track, and organize your favorite movies and TV shows, as well as share your thoughts and recommendations with other film enthusiasts.
      </Typography>
      <Typography variant='h6'>
        Discover the World of Movies and TV Shows
        With Movieception, you can explore an extensive collection of movies and TV shows from various genres, eras, and languages. Our comprehensive database ensures that you can find both popular blockbusters and hidden gems alike. Whether you're in the mood for thrilling action, heartwarming romance, gripping dramas, or side-splitting comedies, Movieception has something for everyone.
      </Typography>
      <Typography variant='h6'>
        Personalized Watchlist, Favorites, and History
        Keep track of what you want to watch, what you love, and what you've already seen with our intuitive profile system. By adding movies and TV shows to your watchlist, you can easily keep tabs on the titles that catch your interest. Curate your favorites collection, showcasing the films and shows that hold a special place in your heart. Moreover, our history feature allows you to revisit the movies and TV shows you've already enjoyed, enabling you to relive those memorable moments.
      </Typography>
      <Typography variant='h6'>
        Connect with Fellow Movie Lovers
        Movieception is not just a platform for discovering and organizing your favorite content; it's also a community of passionate movie lovers. Engage in discussions, share recommendations, and connect with like-minded individuals who share your enthusiasm for the silver screen. Expand your film knowledge, discover new perspectives, and find inspiration from fellow Movieception users.
      </Typography>
      <Typography variant='h6'>
        Start Your Movie Journey with Movieception
        Join Movieception today and embark on an exciting movie journey. Create your profile, explore an extensive collection of movies and TV shows, and connect with a vibrant community of film enthusiasts. Whether you're a casual moviegoer or a die-hard cinephile, Movieception is here to enhance your movie-watching experience.
      </Typography>
      <Typography variant='h6'>
        Lights, camera, action - Let Movieception be your guide into the captivating world of movies and TV shows!
      </Typography>
      <Typography>Need to contact us? Email us on: <a href='mailto:support@movieception.com'>support@movieception.com</a></Typography>
    </div>
  )
}

export default About