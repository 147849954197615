import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { Button } from '@mui/material'

const StyledDiv = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

function ExternalId({ externalId, link }) {
  // setting imdb link either name or title depending on pathname
  const search = useLocation()
  const imdbNameOrTitle = search?.pathname.includes('actors')

  return (
    <StyledDiv>
      <div>
        {link?.vote_average > 0 && (
          <div style={{ display: 'flex', alignItems: 'center', paddingTop: '10px' }}>
            <i className='fas fa-star fa-2x' />
            <span style={{ fontSize: '1.8rem', paddingLeft: '0.4rem' }}>{link?.vote_average.toFixed(1)}</span>
            <span style={{ paddingLeft: '0.4rem', fontSize: '1.2rem' }}>/ 10</span>
          </div>
        )}
      </div>
      <div>
        {externalId?.imdb_id && (
          <a
            href={`https://www.imdb.com/${imdbNameOrTitle ? 'name' : 'title'}/${externalId?.imdb_id}/`}
            target='_blank'
            rel='noreferrer'
            style={{ padding: '0px 5px' }}
          >
            <i className='fab fa-imdb' style={{ fontSize: '42px' }}></i>
          </a>
        )}
        {externalId?.facebook_id && (
          <a
            href={`https://www.facebook.com/${externalId?.facebook_id}`}
            target='_blank'
            rel='noreferrer'
            style={{ padding: '0px 5px' }}
          >
            <i className='fab fa-facebook-square' style={{ fontSize: '42px' }}></i>
          </a>
        )}
        {externalId?.instagram_id && (
          <a
            href={`https://www.instagram.com/${externalId?.instagram_id}`}
            target='_blank'
            rel='noreferrer'
            style={{ padding: '0px 5px' }}
          >
            <i className='fab fa-instagram-square' style={{ fontSize: '42px' }}></i>
          </a>
        )}
        {externalId?.twitter_id && (
          <a
            href={`https://www.twitter.com/${externalId?.twitter_id}`}
            target='_blank'
            rel='noreferrer'
            style={{ padding: '0px 5px' }}
          >
            <i className='fab fa-twitter-square' style={{ fontSize: '42px' }}></i>
          </a>
        )}
      </div>
      <div style={{ padding: '10px 0px 0px 0px' }}>
        {link?.homepage && (
          <Button onClick={() => window.open(link?.homepage, '_blank')} style={{ textDecoration: 'underline' }}>
            Homepage
          </Button>
        )}
      </div>
    </StyledDiv>
  )
}

export default ExternalId
