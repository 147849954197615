import React, { useEffect, useState } from 'react'
import API from '../../client/API-client'
import { useSnackbar } from 'notistack'
import Checkbox from '@mui/material/Checkbox'
import './icons.css'

function WatchedAllCheckbox(props) {
  const [checked, setChecked] = useState()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (props.user?.username) {
      const watchedShow = props.watched?.watchedEpisodes?.find((x) => x.show_title === props.showTitle)
      const season = watchedShow?.seasons
      const episodesArr = season?.find((e) => e.season_id === +props.seasonId)?.episodes
      if (episodesArr?.length === props.watchedAllLength) {
        setChecked(true)
      } else {
        setChecked(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.watched])

  const handleWatched = () => {
    if (props.user?.username) {
      if (checked) {
        API.removeAllEpisodesFromWatched(props.user.username, props.showId, props.showTitle, props.seasonId)
          .then((res) => {
            if (res.status === 200) {
              // find out why props.setRefresh() is not working here
              window.location.reload()
              setChecked(false)
              enqueueSnackbar('Successfully removed all episodes from your watched list!', {
                variant: 'success',
              })
            } else {
              enqueueSnackbar('Something went wrong, please try again later', {
                variant: 'error',
              })
            }
          })
          .catch((err) => {
            enqueueSnackbar('Something went wrong, please try again later', {
              variant: 'error',
            })
          })
      } else {
        API.addAllEpisodesToWatched(
          props.user.username,
          props.showId,
          props.showTitle,
          props.seasonId,
          props.episodeRequest,
          props.showPosterPath,
          props.seasonPosterPath
        ).then((res) => {
          if (res.status === 200) {
            props.setRefresh(true)
            setChecked(true)
            enqueueSnackbar('Successfully added all episodes to your watched list!', {
              variant: 'success',
            })
          } else {
            enqueueSnackbar('Something went wrong, please try again later', {
              variant: 'error',
            })
          }
        })
      }
    } else {
      enqueueSnackbar('You are not logged in!', {
        variant: 'warning',
      })
    }
  }

  return (
    <>
      {props.user?.username && (
        <div className='checkbox-icon'>
          <div className='checkbox-icon-animate'>
            <div className='episode-checkbox'>
              <Checkbox checked={checked ?? false} onClick={() => handleWatched()} />
              <div>{checked ? props.labelChecked : props.labelNotChecked}</div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default WatchedAllCheckbox
