import React from 'react'
import APIClient from '../../client/API-client'
import styled from 'styled-components'
import { Button, Divider, Typography } from '@mui/material'
import { Remove, Add } from '@mui/icons-material'

const StyledSecondDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1rem 0rem;
`
const StyledTypography = styled(Typography)`
  text-align: center;
  max-width: 270px;
  text-wrap: wrap;
`
const StyledCountDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: .8rem;
`
const StyledSpan = styled.span`
  color: yellowgreen;
  &: hover {
    cursor: pointer;
  }
`

const CardInfo = props => {
  const redirect = idx => {
    if (props.info[idx].isMovie) {
      window.open(`/movies/${props.info[idx].id}/${props.info[idx].title}`, '_self')
    } else {
      window.open(`/shows/${props.info[idx].id}/${props.info[idx].title}`, '_self')
    }
  }
  const handleIncreaseRewatches = (id, title) => {
    if (!props.user.username) return
    props.setLoading(true)
    APIClient.increaseShowRewatched(props.user.username, id, title)
      .then(res => {
        if (!res) return
        props.setWatchedEpisodes(res?.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        props.setLoading(false)
      })
  }

  const handleDecreaseRewatches = (id, title) => {
    if (!props.user.username) return
    props.setLoading(true)
    APIClient.decreaseShowRewatched(props.user.username, id, title)
      .then(res => {
        if (!res) return
        props.setWatchedEpisodes(res?.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        props.setLoading(false)
      })
  }
  return (
    <StyledSecondDiv>
      <StyledTypography
        component={'span'}
        variant='h6'
        sx={{ mt: -2.5 }}
      >
        {props.movie?.title || props.movie?.original_title || props.movie?.name || props.movie?.original_name}
        <Divider sx={{ marginBottom: '.5rem' }} />
      </StyledTypography>
      {props.movie.watchedSeasons && (
        <div style={{ textAlign: 'center', padding: '0rem 0.5rem', fontStyle: 'italic' }}>
          <div style={{ color: 'yellowgreen' }}>
            Watched {props.movie.watchedSeasons} out of {props.movie.NumberOfSeasons} {props.movie.watchedSeasons > 1 ? 'seasons' : 'season'}
            <br />
            {/* adding comma or and if it's the last one or if there are only 2 seasons */}
            {/* not sure if I want this here; */}
            {/* {props.movie.watchedSeasons !== props.movie.NumberOfSeasons && props.movie?.seasonNumbers.reduce((text, value, i, array) => text + (i < array.length - 1 ? ', ' : ' and ') + value)} */}
          </div>
          <Divider sx={{ margin: '.5rem' }} />
          <div style={{ color: 'lightcoral' }}>
            {/* Total episode count: {props.movie.totalWatchedEpisodes}. */}
            Watched {props.movie.totalWatchedEpisodes > props.movie.NumberOfEpisodes ? props.movie.NumberOfEpisodes : props.movie.totalWatchedEpisodes} out of {props.movie.NumberOfEpisodes} episodes.
          </div>
          {(props.movie.rewatch_count > 1 && props.movie.watchedSeasons === props.movie.NumberOfSeasons) &&
            <div style={{ color: 'lightblue', padding: '0rem .8rem' }}>
              Totaled to {props.movie.totalWatchedEpisodes * props.movie.rewatch_count} {`${props.movie.totalWatchedEpisodes > 1 ? 'episodes' : 'episode'}`} by watching {props.movie?.title || props.movie?.original_title || props.movie?.name || props.movie?.original_name} {props.movie.rewatch_count} times.
            </div>
          }
        </div>
      )}
      {(props.title === 'Watched TV Shows' && !props.movie.seasons) &&
        <Typography component={'span'} variant='body2' style={{ textAlign: 'center', padding: '0rem 0.5rem' }}>
          Looks like you haven't added any episodes from {props.movie?.title || props.movie?.original_title || props.movie?.name || props.movie?.original_name} to your watched episodes list, you can click <StyledSpan onClick={() => redirect(props.idx)}>here</StyledSpan> to go to show page and add some!
        </Typography>
      }
      {(props.title === 'Watched TV Shows' && props.movie.seasons) && (props.movie.totalWatchedEpisodes >= props.movie.NumberOfEpisodes) &&
        <>
          <Typography
            style={{ textAlign: 'center', marginTop: '1rem' }}
            component={'span'} variant='body2'>
            Number of Rewatches
          </Typography>
          <StyledCountDiv>
            <Button
              style={{
                outline: 'none',
                visibility: props.movie.rewatch_count ? props.movie.rewatch_count <= 1 && 'hidden' : 'hidden'
              }}
              onClick={() => handleDecreaseRewatches(props.movie.id, props.movie.title)}
            >
              <Remove fontSize="small" />
            </Button>
            <div style={{ margin: '0rem 1rem' }}>
              {props.movie.rewatch_count ?? 1}
            </div>
            <Button
              style={{ outline: 'none' }}
              onClick={() => handleIncreaseRewatches(props.movie.id, props.movie.title)}
            >
              <Add fontSize="small" />
            </Button>
          </StyledCountDiv>
          {props.movie.rewatch_count > 5 &&
            <Typography
              style={{
                textAlign: 'center',
                margin: '1rem 1rem 0rem 1rem',
                fontStyle: 'italic',
                color: 'orange'
              }}
              component={'span'}
              variant='body2'
            >
              Wow, okay more than 5 rewatches, {props.movie?.title || props.movie?.original_title || props.movie?.name || props.movie?.original_name} is for sure one of your favorites!
            </Typography>
          }
        </>
      }
    </StyledSecondDiv>
  )
}

export default CardInfo