import { useEffect, useState } from 'react'

const useTitle = (title) => {
    const [documentTitle, setDocumentTitle] = useState(title)

    useEffect(() => {
        document.title = documentTitle
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title])

    return [documentTitle, setDocumentTitle]
}

export { useTitle }
