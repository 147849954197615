import React from 'react'
import { Tooltip } from '@mui/material'
import styled from 'styled-components'

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`
const StyledOverviewDiv = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  &: hover {
    cursor: pointer;
  }
`
const StyledSpan = styled.span`
color: #b6894e;
`

const Info = ({ data, upcoming, movie, show, index }) => {
  const date = new Date(data?.[index].release_date || data?.[index].first_air_date)
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  }
  return (
    <>
      {upcoming &&
        <StyledDiv>
          <div>
            Release Date: <StyledSpan>{date.toLocaleString('en-US', options)}</StyledSpan>
          </div>
          <Tooltip title={data?.[index].overview}>
            <StyledOverviewDiv>
              {data?.[index].overview}
            </StyledOverviewDiv>
          </Tooltip>
        </StyledDiv>
      }
      {movie &&
        <StyledDiv>
          <div>
            Release Date: <StyledSpan>{date.toLocaleString('en-US', options)}</StyledSpan>
          </div>
          <Tooltip title={data?.[index].overview}>
            <StyledOverviewDiv>
              {data?.[index].overview}
            </StyledOverviewDiv>
          </Tooltip>
        </StyledDiv>
      }
      {show &&
        <StyledDiv>
          <div>
            First Air Date: <StyledSpan>{date.toLocaleString('en-US', options)}</StyledSpan>
          </div>
          <Tooltip title={data?.[index].overview}>
            <StyledOverviewDiv>
              {data?.[index].overview}
            </StyledOverviewDiv>
          </Tooltip>
        </StyledDiv>
      }
    </>
  )
}

export default Info