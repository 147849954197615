import React, { useEffect, useState } from 'react'
import movieClient from '../../client/movie-client'
import { Carousel, HomeCarousels, RandomModal } from '../../components'
import showClient from '../../client/show-client'
import styled from 'styled-components'
import { StyledCircularProgressDiv } from '../../utils/styled'
import { CircularProgress } from '@mui/material'
import NoResultPage from '../NoResultPage'

const CustomBottomDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    align-items: center;
    justify-content: space-evenly;
    gap: 2rem;
    transition: opacity 1s ease-in-out;
`

const Home = ({ user }) => {
    // eslint-disable-next-line no-unused-vars
    const [page, setPage] = useState(1)
    const [greet, setGreet] = useState()
    const [trending, setTrending] = useState()
    const [upcoming, setUpcoming] = useState()
    const [loading, setLoading] = useState(true)
    const [trendingShow, setTrendingShow] = useState()
    const [trendingMovie, setTrendingMovie] = useState()
    const [randomModalOpen, setRandomModalOpen] = useState(false)
    const [opacity, setOpacity] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setOpacity('1')
        }, 800)
    }, [])

    useEffect(() => {
        const hours = new Date().getHours()

        if (hours < 12 && hours > 6) {
            setGreet('Good Morning')
        } else if (hours >= 12 && hours <= 17) {
            setGreet('Good Afternoon')
        } else {
            setGreet('Good Evening')
        }
    }, [])

    useEffect(() => {
        const fetchData = () => {
            setLoading(true)
            const getUpcoming = movieClient.getUpcoming()
            const getTrending = movieClient.getTrending(page)
            const getTrendingShow = showClient.getTrending(page)
            const getTrendingMovie = movieClient.getTrendingMovies(page)
            Promise.all([getUpcoming, getTrending, getTrendingShow, getTrendingMovie])
                .then(res => {
                    if (!res) return
                    setUpcoming(res[0].data)
                    setTrending(res[1].data)
                    setTrendingShow(res[2].data)
                    setTrendingMovie(res[3].data)
                })
                .catch(err => console.log(err))
                .finally(() => setLoading(false))
        }
        fetchData()
    }, [page])

    return (
        loading ? (
            <StyledCircularProgressDiv>
                <CircularProgress size={70} />
            </StyledCircularProgressDiv>
        ) : (
            <>
                {trending &&
                    <Carousel
                        trendingMovie={trending}
                        greet={greet}
                        user={user}
                        setRandomModalOpen={setRandomModalOpen}
                    />
                }
                {(upcoming && trendingMovie && trendingShow) && (
                    <CustomBottomDiv style={{ opacity: opacity }}>
                        <HomeCarousels data={upcoming.results} title='Upcoming Movies' upcoming={true} />
                        <HomeCarousels data={trendingMovie} title='Trending Movies' movie={true} />
                        <HomeCarousels data={trendingShow} title='Trending Shows' show={true} />
                    </CustomBottomDiv>
                )}
                {!(trending && upcoming && trendingMovie && trendingShow) && (
                    <NoResultPage />
                )}
                <RandomModal randomModal={randomModalOpen} setRandomModal={setRandomModalOpen} />
            </>
        )
    )
}

export default Home