import React, { useEffect, useState } from 'react'
import { StyledBannerDiv } from '../../utils/styled'
import PlaceHolder from '../../assets/images/banner-placeholder.png'
import styled from 'styled-components'

const StyledImgDiv = styled.div`
  transition: all ease-in-out 0.8s;
`

function Banner({ link }) {
    const [opacity, setOpacity] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setOpacity('0.488')
        }, 1000)
    }, [])
    return (
        <StyledBannerDiv>
            <StyledImgDiv
                style={{
                    backgroundSize: link ? 'cover' : 'none',
                    backgroundImage: link ? `url(https://image.tmdb.org/t/p/original${link})` : `url(${PlaceHolder})`,
                    backgroundPosition: '50% 10%',
                    opacity: opacity,
                    height: '762px',
                }}
            />
        </StyledBannerDiv>
    )
}

export default Banner
