import React, { useState } from 'react'
import styled from 'styled-components'
import { StyledCircularProgressDiv } from '../../utils/styled'
import { CircularProgress } from '@mui/material'

const StyledMainContainer = styled.div`
  text-align: center;
  transition: opacity ease-in-out 0.8s;
`

const EmptyStateProfiles = () => {
  const [opacity, setOpacity] = useState(0)

  setTimeout(() => {
    setOpacity(1)
  }, 500)

  return (
    <>
      {opacity < 0 ? (
        <StyledCircularProgressDiv>
          <CircularProgress size={70} />
        </StyledCircularProgressDiv>
      ) : (
        <StyledMainContainer style={{ opacity: opacity, marginTop: '8rem' }}>
          <div style={{ fontSize: '36px' }}>
            Click <a href='/discover'>here</a> to discover!
          </div>
        </StyledMainContainer>
      )}
    </>
  )
}

export default EmptyStateProfiles
