import { useEffect, useState } from 'react'
import { Link, Typography } from '@mui/material'

const Fallback = ({ error }) => {
  // { error } props and error.message will render the message to the page
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1)
    }, 800)
  }, [])

  return (
    <Typography
      variant='h4'
      sx={{
        textAlign: 'center',
        opacity: opacity,
        transition: 'opacity 1s ease-in-out',
        m: 10
      }}
    >
      Something went wrong, you can retry by clicking <Link color='secondary' underline='none' href='#' sx={{ cursor: 'pointer', '&: hover': { color: 'coral', textDecoration: 'none' } }} onClick={() => window.location.reload()}>here</Link>, or you can click <Link color='secondary' underline='none' href='/home' sx={{ cursor: 'pointer', '&: hover': { color: 'coral', textDecoration: 'none' } }}>here</Link> to go back home!
    </Typography>
  )
}

export default Fallback