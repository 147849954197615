import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from '@mui/material'
import { base_url } from '../../utils/helper'
import ExternalId from '../../components/ExternalId'
import MovieCredits from '../../components/MovieCredits'
import { StyledCollectionMap, StyledCollectionOverviews, StyledCreditsCastsPosterImg, StyledCreditsGridDiv, StyledMarginBottom } from '../../utils/styled'

const StyledSpan = styled.span`
    color: #b6894e;
`

const ActorOverview = ({ actor, slicedPictures, externalId }) => {
  const [truncate, setTruncate] = useState(true)

  const date = new Date(actor?.birthday)
  const deathDate = actor?.deathday && new Date(actor?.deathday)
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  }

  return (
    <StyledCollectionOverviews>
      <div style={{ marginTop: '2rem' }}>
        <h2>{actor?.name}</h2>
      </div>
      <div>
        <ExternalId externalId={externalId} link={actor} />
      </div>
      <div>
        {actor?.birthday && (
          <div>
            <div>
              Born <StyledSpan>{date.toLocaleString('en-US', options)}</StyledSpan> in{' '} <StyledSpan>{actor?.place_of_birth}</StyledSpan>
            </div>
            {deathDate && (
              <div>
                Passed Away on <StyledSpan>{deathDate.toLocaleString('en-US', options)}</StyledSpan>
              </div>
            )}
          </div>
        )}
        <StyledMarginBottom>
          {/* at least 382 is required for the overview to have truncate added into */}
          <div style={{ marginTop: '1rem' }} className={`${(truncate && actor.biography?.length > 382) && 'truncate'}`}>{actor?.biography}</div>
          {actor.biography?.length > 382 &&
            <Link
              href='#'
              style={{ marginTop: '0.5rem' }}
              onClick={e => {
                setTruncate(!truncate)
                e.preventDefault()
              }}
            >
              Read {truncate ? 'More' : 'Less'}
            </Link>
          }
        </StyledMarginBottom>
        <StyledMarginBottom>
          <h4>Known For</h4>
          <MovieCredits actor={actor} />
        </StyledMarginBottom>
        <h4>{actor?.name}'s Images</h4>
        <StyledCollectionMap>
          <StyledCreditsGridDiv>
            {slicedPictures?.map(
              (x, id) =>
                x?.file_path && (
                  <StyledCreditsCastsPosterImg key={id} src={`${base_url}${x.file_path}`} alt={x?.width} style={{ margin: '0.2rem' }} />
                )
            )}
          </StyledCreditsGridDiv>
        </StyledCollectionMap>
      </div>
    </StyledCollectionOverviews >
  )
}

export default ActorOverview