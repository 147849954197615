import React, { useState, useEffect } from 'react'
import SearchModal from '../SearchModal'
import { links, avatarLinks } from './links'
import MenuIcon from '@mui/icons-material/Menu'
import { StyledSearchIcon } from '../../utils/styled'
import LocalMoviesIcon from '@mui/icons-material/LocalMovies'
import { stringAvatar } from '../ProfileComponents/upper-profile'
import { AppBar, Box, Toolbar, Typography, IconButton, Container, Avatar, MenuItem, Link, Menu, Tooltip, CircularProgress } from '@mui/material'
import styled from 'styled-components'

const StyledTypography = styled(Typography)`
    &:hover {
        text-decoration: none;
        color: white;
    }
`
const StyledLink = styled(Link)`
    &:hover {
        cursor: pointer;
        text-decoration: none;
    }
`

function ResponsiveAppBar({ loggedInUser, status, loginWithPopup, logout, isLoading, user }) {
    const [anchorElNav, setAnchorElNav] = useState(null)
    const [anchorElUser, setAnchorElUser] = useState(null)
    const [searchModalOpen, setSearchModalOpen] = useState(false)
    const [opacity, setOpacity] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            setOpacity('1')
        }, 500)
    }, [])

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const handleLogout = link => {
        if (link.title === 'Logout') {
            logout()
        } else {
            window.location.href = link.href
        }
        handleCloseUserMenu()
    }

    const handleLoginNonMobile = link => {
        if (link.title === 'Login') return loginWithPopup()
    }

    const handleLoginMobile = link => {
        if (link.title === 'Login') {
            loginWithPopup()
            handleCloseNavMenu()
            return
        }
        window.location.href = link.href
        handleCloseNavMenu()
    }

    const loginBtnCheck = link => {
        return !(link.title === 'Login' && user?.nickname) && !isLoading
    }

    const currentHref = window.location.href
    const url = (currentHref.split('/')[4] == null || undefined) && currentHref.split('/')[3]?.charAt(0).toUpperCase() + currentHref.split('/')[3]?.slice(1)

    return (
        <AppBar position="sticky" style={{ backgroundColor: 'rgba(55, 0, 0, 1)' }}>
            <Container maxWidth="l">
                <Toolbar disableGutters>
                    <LocalMoviesIcon style={{ opacity: opacity, transition: 'opacity ease-in-out 0.8s' }} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <StyledTypography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                        style={{ opacity: opacity, transition: 'opacity ease-in-out 0.8s' }}
                    >
                        Movieception
                    </StyledTypography>
                    <StyledSearchIcon style={{ opacity: opacity, transition: 'opacity ease-in-out 0.8s' }} sx={{ display: { xs: 'none', md: 'flex' } }} onClick={() => setSearchModalOpen(true)} />
                    <Box style={{ opacity: opacity, transition: 'opacity ease-in-out 0.8s' }} sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            style={{ outline: 'none' }}
                        >
                            <MenuIcon />
                        </IconButton>
                        {user?.nickname && <IconButton
                            size="large"
                            color="inherit"
                            onClick={() => setSearchModalOpen(true)}
                        >
                            <StyledSearchIcon />
                        </IconButton>}
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {links.map((link, idx) => (
                                !(link.title === 'Login' && user?.nickname) &&
                                <MenuItem
                                    key={idx}
                                    onClick={() => handleLoginMobile(link)}
                                >
                                    <Link
                                        style={{ color: 'white', textDecoration: 'none' }}
                                        href={link.href}
                                    >
                                        {link.title}
                                    </Link>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <LocalMoviesIcon style={{ opacity: opacity, transition: 'opacity ease-in-out 0.8s' }} sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <StyledTypography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                        style={{ opacity: opacity, transition: 'opacity ease-in-out 0.8s' }}
                    >
                        Movieception
                    </StyledTypography>
                    {!user?.nickname && <StyledSearchIcon sx={{ display: { md: 'none' } }} onClick={() => setSearchModalOpen(true)} />}
                    <Box style={{ opacity: opacity, transition: 'opacity ease-in-out 0.8s' }} sx={{ justifyContent: 'flex-end', flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {links.map((link, idx) => (
                            loginBtnCheck(link) &&
                            <StyledLink
                                key={idx}
                                href={link.href}
                                onClick={() => handleLoginNonMobile(link)}
                                underline="none"
                                sx={{
                                    color: (url && url.includes(link.title)) ? 'coral' : 'white',
                                    padding: .5,
                                    fontSize: '1.1rem',
                                    fontFamily: 'Times New Roman, sans-serif',
                                    transition: 'color .5s ease-in-out',
                                    "&: hover": { color: 'coral' },
                                }}
                            >
                                {link.title}
                            </StyledLink>
                        ))}
                        {((!loggedInUser?.username && status !== 'idle') || status === 'loading' || isLoading) &&
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '0rem 0.25rem'
                            }}>
                                <CircularProgress size={35} />
                            </div>
                        }
                    </Box>
                    {loggedInUser?.username &&
                        <div style={{ opacity: opacity, transition: 'opacity ease-in-out 0.8s' }}>
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }} style={{ outline: 'none' }}>
                                <Tooltip
                                    arrow
                                    title={`Hi, ${user?.nickname && user?.nickname ? user?.nickname.split(' ')[0] : user?.nickname}!`}
                                >
                                    <Avatar
                                        alt="E" src={user?.picture}
                                        {...stringAvatar(user?.nickname)}
                                    />
                                </Tooltip>
                            </IconButton>
                            <Box sx={{ flexGrow: 0 }}>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {avatarLinks.map((link, idx) => (
                                        <MenuItem
                                            key={idx}
                                            onClick={() => handleLogout(link)}
                                        >
                                            {(isLoading || status === 'loading') && link.title === 'Logout' ?
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <CircularProgress size={35} />
                                                </div>
                                                :
                                                <Link
                                                    href={link.href}
                                                    style={{ color: 'white', textDecoration: 'none' }}
                                                >
                                                    {link.title}
                                                </Link>
                                            }
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </div>
                    }
                </Toolbar>
            </Container>
            <SearchModal show={searchModalOpen} handleClose={() => setSearchModalOpen(false)} />
        </AppBar >
    )
}
export default ResponsiveAppBar;