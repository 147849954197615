import React, { useEffect, useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import { base_url } from '../../utils/helper'
import {
    StyledCreditsCastPosters,
    StyledCreditsCastsMap,
    StyledCreditsCastsName,
    StyledCreditsCastsEpisodes,
    StyledCreditsCastsCharacter,
    StyledCreditsCastsPosterImg,
    StyledMovieCreditsBtn,
    StyledCreditsGridDiv,
    StyledFlexDiv
} from '../../utils/styled'
import showRoutes from '../../client/show-client'
import movieRoutes from '../../client/movie-client'
import PlaceHolder from '../../assets/images/placeholder.png'

function Credits({ movie, show, credits }) {
    const [cast, setCast] = useState([])
    const [opacity, setOpacity] = useState(0)
    const [loadMore, setLoadMore] = useState(10)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (movie?.id) {
            const fetchData = async () => {
                movieRoutes.getMovieCredits(movie.id)
                    .then(res => setCast(res.data.cast))
            }
            fetchData()
        } else if (show?.id) {
            const fetchData = async () => {
                showRoutes.getShowCredits(show.id)
                    .then(res => setCast(res.data.cast))
            }
            fetchData()
        } else {
            setCast(credits?.cast)
        }
    }, [movie?.id, show?.id, credits])

    useEffect(() => {
        setTimeout(() => {
            setOpacity('1')
        }, 800)
    }, [])

    const handleLoadMore = () => {
        setLoading(true)
        setTimeout(() => {
            setLoadMore(loadMore + 10)
            setLoading(false)
        }, 800)
    }

    const redirect = (theCast) => {
        window.location.assign(`/actors/${theCast?.id}/${theCast?.name || theCast?.original_name}`)
    }

    const slicedCast = cast?.slice(0, loadMore)

    return (
        <>
            <div>
                <StyledCreditsCastPosters>
                    <StyledCreditsGridDiv>
                        {slicedCast?.map(theCast => (
                            <div key={theCast.id}>
                                <StyledCreditsCastsMap>
                                    <StyledCreditsCastsPosterImg
                                        style={{ opacity: opacity }}
                                        onClick={() => redirect(theCast)}
                                        src={theCast?.profile_path ? `${base_url}${theCast?.profile_path}` : PlaceHolder}
                                        alt={theCast?.original_name}
                                    />
                                    <StyledCreditsCastsName>{theCast?.original_name}</StyledCreditsCastsName>
                                    {theCast.roles ? (
                                        <StyledCreditsCastsCharacter>{theCast.roles[0].character}</StyledCreditsCastsCharacter>
                                    ) : (
                                        <StyledCreditsCastsCharacter>{theCast.character}</StyledCreditsCastsCharacter>
                                    )}
                                    {theCast?.total_episode_count && (
                                        <StyledCreditsCastsEpisodes>{`${theCast?.total_episode_count} ${theCast?.total_episode_count > 1 ? 'Episodes' : 'Episode'
                                            }`}</StyledCreditsCastsEpisodes>
                                    )}
                                </StyledCreditsCastsMap>
                            </div>
                        ))}
                    </StyledCreditsGridDiv>
                </StyledCreditsCastPosters>
                {cast?.length > loadMore && (
                    <StyledMovieCreditsBtn>
                        {!loading ?
                            <Button
                                variant='outlined'
                                onClick={() => handleLoadMore()}
                            >
                                Load More
                            </Button>
                            : (
                                <StyledFlexDiv>
                                    <CircularProgress size={35} />
                                </StyledFlexDiv>
                            )}
                    </StyledMovieCreditsBtn>
                )}
            </div>
        </>
    )
}

export default Credits
